import { ReactComponent as Arrow } from './icons/arrow.svg';
import { ReactComponent as ArrowBack } from './icons/arrow-back.svg';
import { ReactComponent as Check } from './icons/check.svg';
import { ReactComponent as CheckCircle } from './icons/check-circle.svg';
import { ReactComponent as Chevron } from './icons/chevron.svg';
import { ReactComponent as ChevronSquare } from './icons/chevron-square.svg';
import { ReactComponent as Copy } from './icons/copy.svg';
import { ReactComponent as Cross } from './icons/cross.svg';
import { ReactComponent as CrossCircle } from './icons/cross-circle.svg';
import { ReactComponent as Document } from './icons/document.svg';
import { ReactComponent as Dots } from './icons/dots.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as DownloadBatch } from './icons/download-batch.svg';
import { ReactComponent as GoToLink } from './icons/go-to-link.svg';
import { ReactComponent as Mail } from './icons/mail.svg';
import { ReactComponent as Note } from './icons/note.svg';
import { ReactComponent as Pencil } from './icons/pencil.svg';
import { ReactComponent as PendingCircle } from './icons/pending-circle.svg';
import { ReactComponent as Plus } from './icons/plus.svg';
import { ReactComponent as Send } from './icons/send.svg';
import { ReactComponent as Ship } from './icons/ship.svg';
import { ReactComponent as Verify } from './icons/verify.svg';
import { ReactComponent as Warning } from './icons/warning.svg';
import { ReactComponent as WarningFilled } from './icons/warning-filled.svg';

export enum IconType {
  Arrow,
  ArrowBack,
  CheckCircle,
  Check,
  Chevron,
  ChevronSquare,
  Copy,
  Cross,
  CrossCircle,
  Document,
  Dots,
  Download,
  DownloadBatch,
  GoToLink,
  Mail,
  Note,
  Pencil,
  PendingCircle,
  Plus,
  Send,
  Ship,
  Speaker,
  Verify,
  Warning,
  WarningFilled,
}

type IconMap = {
  width?: string;
  height?: string;
  source: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

/* eslint-disable prettier/prettier */
export const icons = new Map<IconType, IconMap>([
  [IconType.Arrow, { source: Arrow, width: '20px', height: '20px' }],
  [IconType.ArrowBack, { source: ArrowBack, width: '18px', height: '18px' }],
  [IconType.Check, { source: Check, width: '18px', height: '14px' }],
  [IconType.CheckCircle, { source: CheckCircle, width: '20px', height: '20px' }],
  [IconType.Chevron, { source: Chevron, width: '10px', height: '16px' }],
  [IconType.ChevronSquare, { source: ChevronSquare, width: '40px', height: '40px' }],
  [IconType.Copy, { source: Copy, width: '20px', height: '20px' }],
  [IconType.Cross, { source: Cross, width: '20px', height: '20px' }],
  [IconType.CrossCircle, { source: CrossCircle, width: '20px', height: '20px' }],
  [IconType.Document, { source: Document, width: '20px', height: '20px' }],
  [IconType.Dots, { source: Dots, width: '16px', height: '16px' }],
  [IconType.Download, { source: Download, width: '20px', height: '20px' }],
  [IconType.DownloadBatch, { source: DownloadBatch, width: '24px', height: '24px' }],
  [IconType.GoToLink, { source: GoToLink, width: '24px', height: '24px' }],
  [IconType.Mail, { source: Mail, width: '24px', height: '24px' }],
  [IconType.Note, { source: Note, width: '18px', height: '18px' }],
  [IconType.Pencil, { source: Pencil, width: '24px', height: '24px' }],
  [IconType.PendingCircle, { source: PendingCircle, width: '20px', height: '20px' }],
  [IconType.Plus, { source: Plus, width: '18px', height: '18px' }],
  [IconType.Send, { source: Send, width: '24px', height: '24px' }],
  [IconType.Ship, { source: Ship, width: '24px', height: '24px' }],
  [IconType.Verify, { source: Verify, width: '18px', height: '18px' }],
  [IconType.Warning, { source: Warning, width: '24px', height: '24px' }],
  [IconType.WarningFilled, { source: WarningFilled, width: '24px', height: '24px' }],
]);
