import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { utils, writeFile } from 'xlsx';

import { paths } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import Card from 'src/shared/components/Card/Card';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';
import Table from 'src/shared/components/Table/Table';
import Tabs from 'src/shared/components/Tabs/Tabs';
import { DATE_WITHOUT_TIMEZONE } from 'src/shared/utils';

import { useCheckError } from 'src/features/hooks';
import {
  useGetPayoutsQuery,
  useGetTransactionsQuery,
} from 'src/features/payment/api/paymentApi';
import PaymentStatus, {
  PaymentStatusValue,
} from 'src/features/payment/components/PaymentStatus/PaymentStatus';
import { Payout, Transaction } from 'src/features/payment/models';

import './CoCPayments.scss';

const pound = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

const CoCRequests = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [transactionDateFrom, setTransactionDateFrom] = useState<Dayjs | null>(
    null
  );
  const [payoutDateFrom, setPayoutDateFrom] = useState<Dayjs | null>(null);
  const [transactionDateTo, setTransactionDateTo] = useState<Dayjs | null>(
    null
  );
  const [payoutDateTo, setPayoutDateTo] = useState<Dayjs | null>(null);
  const { data: transactions, isError: isTransactionsError } =
    useGetTransactionsQuery({
      dateFrom: transactionDateFrom?.startOf('day').unix(),
      dateTo: transactionDateTo?.endOf('day').unix(),
    });
  const { data: payouts, isError: isPayoutsError } = useGetPayoutsQuery({
    dateFrom: payoutDateFrom?.startOf('day').unix(),
    dateTo: payoutDateTo?.endOf('day').unix(),
  });

  const TABS = useMemo(
    () => [
      {
        label: 'Transaction History',
      },
      {
        label: 'Account Payouts',
      },
    ],
    []
  );

  const minDate = useMemo(() => {
    if (currentTab === 0) {
      return transactionDateFrom ? dayjs(transactionDateFrom) : undefined;
    }
    return payoutDateFrom ? dayjs(payoutDateFrom) : undefined;
  }, [currentTab, payoutDateFrom, transactionDateFrom]);

  useCheckError(isTransactionsError, 'Error loading transactions');
  useCheckError(isPayoutsError, 'Error loading payouts');

  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const onExportClick = () => {
    const ws = utils.table_to_sheet(
      document.getElementById(currentTab === 0 ? 'transactions' : 'payouts'),
      { dateNF: DATE_WITHOUT_TIMEZONE, cellDates: true, raw: true }
    );
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFile(
      wb,
      `${currentTab === 0 ? 'transactions-history' : 'account-payouts'}.xlsx`
    );
  };

  const transactionHistoryTable = (
    <Table<Transaction>
      tableId="transactions"
      columns={[
        {
          header: 'Payment Date',
          accessorKey: 'paymentDate',
          id: 'date',
          size: 100,
          Cell: ({ cell }) =>
            dayjs.unix(cell.getValue<number>()).format(DATE_WITHOUT_TIMEZONE),
        },
        {
          header: 'Payee Name',
          accessorKey: 'payeeName',
        },
        {
          header: 'Payee Email',
          accessorKey: 'payeeEmail',
        },
        {
          header: 'Course',
          accessorKey: 'course',
          size: 100,
        },
        {
          header: 'Gross amount',
          accessorKey: 'grossAmount',
          size: 100,
          Cell: ({ cell }) => `${pound.format(cell.getValue<number>() / 100)}`,
        },
        {
          header: 'Fee',
          accessorKey: 'fee',
          size: 100,
          Cell: ({ cell }) => `${pound.format(cell.getValue<number>() / 100)}`,
        },
        {
          header: 'Net amount',
          accessorKey: 'netAmount',
          size: 100,
          Cell: ({ cell }) => `${pound.format(cell.getValue<number>() / 100)}`,
        },
        {
          header: '',
          id: 'form',
          size: 50,
          Cell: ({ row }) => {
            return (
              <Link
                to={paths.cocRequestById(
                  (row.original as Transaction).objectId
                )}
              >
                <Icon icon={IconType.ChevronSquare} />
              </Link>
            );
          },
        },
      ]}
      data={transactions?.data ?? []}
      enableSorting
      initialState={{
        sorting: [
          {
            id: 'date',
            desc: true,
          },
        ],
      }}
    />
  );

  const accountPayoutsTable = (
    <Table<Payout>
      tableId="payouts"
      columns={[
        {
          header: 'Date',
          accessorKey: 'paymentDate',
          id: 'date',
          size: 100,
          Cell: ({ cell }) =>
            dayjs.unix(cell.getValue<number>()).format(DATE_WITHOUT_TIMEZONE),
        },
        {
          header: 'Transaction ID',
          accessorKey: 'transactionId',
          id: 'uid',
        },
        {
          header: 'Description',
          accessorKey: 'description',
          id: 'description',
        },
        {
          header: 'Amount',
          accessorKey: 'amount',
          id: 'amount',
          size: 80,
          Cell: ({ cell }) => `${pound.format(cell.getValue<number>() / 100)}`,
        },
        {
          header: 'Payment status',
          accessorKey: 'status',
          id: 'status',
          minSize: 60,
          Cell: ({ cell }) => (
            <PaymentStatus value={cell.getValue<PaymentStatusValue>()} />
          ),
        },
      ]}
      data={payouts?.data ?? []}
      enableSorting
      initialState={{
        sorting: [
          {
            id: 'date',
            desc: true,
          },
        ],
      }}
      state={{
        columnOrder: ['date', 'uid', 'description', 'amount'],
      }}
    />
  );

  return (
    <Page>
      <div className="coc-payments">
        <h1>GUEST payments</h1>

        <Card className="coc-payments__table-container">
          <>
            <div className="coc-payments__card-header">
              <Tabs
                value={currentTab}
                values={TABS}
                onChange={handleTabClick}
              />
              <div className="coc-payments__table-actions">
                <div className="coc-payments__date-range">
                  <DatePicker<Dayjs>
                    defaultValue={null}
                    className="date-range__input"
                    format="DD-MM-YYYY"
                    value={
                      currentTab === 0 ? transactionDateFrom : payoutDateFrom
                    }
                    onChange={(value) =>
                      currentTab === 0
                        ? setTransactionDateFrom(value)
                        : setPayoutDateFrom(value)
                    }
                  />
                  —
                  <DatePicker<Dayjs>
                    defaultValue={null}
                    className="date-range__input"
                    format="DD-MM-YYYY"
                    minDate={minDate}
                    value={currentTab === 0 ? transactionDateTo : payoutDateTo}
                    onChange={(value) =>
                      currentTab === 0
                        ? setTransactionDateTo(value)
                        : setPayoutDateTo(value)
                    }
                  />
                </div>
                <Button
                  variant="contained"
                  onClick={onExportClick}
                  disabled={
                    currentTab === 0
                      ? transactions?.data.length === 0
                      : payouts?.data.length === 0
                  }
                >
                  Export in Excel
                </Button>
              </div>
            </div>
            {currentTab === 0 ? transactionHistoryTable : accountPayoutsTable}
          </>
        </Card>
      </div>
    </Page>
  );
};

export default CoCRequests;
