import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, IconButton, Popover } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';

import Page from 'src/layouts/BaseLayout/components/Page/Page';
import Card from 'src/shared/components/Card/Card';
import Dropdown from 'src/shared/components/Dropdown/Dropdown';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';
import SearchBar from 'src/shared/components/SearchBar/SearchBar';
import Table from 'src/shared/components/Table/Table';

import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { useGetResponsiblePersonsQuery } from 'src/features/responsiblePersons/api/responsiblePersonsApi';
import CoCStatus from 'src/features/responsiblePersons/components/CoCStatus/CoCStatus';
import {
  CoCStatus as CoCStatusValues,
  ResponsiblePerson,
} from 'src/features/responsiblePersons/models';
import { setResponsiblePerson } from 'src/features/responsiblePersons/slices/responsiblePersonsSlice';
import { store } from 'src/features/store/store';

import './ResponsiblePersons.scss';

type ResponsiblePersonsTableProps = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  yachtName: string;
  cocStatus: string;
  position: string;
  approvedSSTs: number;
};

const DROPDOWN_ITEMS = [
  {
    text: 'Change',
    icon: <Icon icon={IconType.Pencil} />,
    onClick: (person: ResponsiblePerson) => {
      store.dispatch(changeModal(MODALS.EDIT_RP_FORM));
      store.dispatch(setResponsiblePerson(person));
    },
  },
  {
    text: 'Delete',
    icon: <Icon icon={IconType.CrossCircle} />,
    className: 'responsible-person__action-button_delete',
  },
];

const ResponsiblePersons = () => {
  const [search, setSearch] = useState<string>('');
  const [persons, setPersons] = useState<ResponsiblePerson[]>([]);
  const { data: responsiblePersons, isError } = useGetResponsiblePersonsQuery();

  useCheckError(isError, 'Error loading Responsible Persons');

  useCheckAuthorization();

  const filterResponsiblePersons = useCallback(
    () =>
      responsiblePersons
        ? responsiblePersons?.data.filter(
            (person) =>
              person.firstName.toLowerCase().includes(search.toLowerCase()) ||
              person.middleName.toLowerCase().includes(search.toLowerCase()) ||
              person.lastName.toLowerCase().includes(search.toLowerCase()) ||
              person.email.toLowerCase().includes(search.toLowerCase()) ||
              person.yachtName.toLowerCase().includes(search.toLowerCase())
          )
        : [],
    [search, responsiblePersons]
  );

  useEffect(() => {
    setPersons(filterResponsiblePersons);
  }, [filterResponsiblePersons, search]);

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'First name',
        accessorKey: 'firstName',
      },
      {
        header: 'Middle name',
        accessorKey: 'middleName',
      },
      {
        header: 'Last name',
        accessorKey: 'lastName',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Yacht Name',
        accessorKey: 'yachtName',
      },
      {
        header: 'CoC Status',
        accessorKey: 'cocStatus',
        size: 135,
        Cell: ({ cell }) => (
          <CoCStatus value={cell.getValue<CoCStatusValues>()} />
        ),
      },
      {
        header: 'Position',
        accessorKey: 'position',
      },
      {
        header: 'Nº of SSTs approved',
        accessorKey: 'approvedSSTs',
        size: 80,
      },
      {
        header: '',
        id: 'actions',
        size: 50,
        maxSize: 50,
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
            null
          );
          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };
          const dropdownIsOpen = Boolean(anchorEl);

          const handleClose = () => {
            setAnchorEl(null);
          };

          return (
            <>
              <IconButton onClick={handleClick} className="button_row-actions">
                <Icon icon={IconType.Dots} />
              </IconButton>
              <Popover
                open={dropdownIsOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Dropdown<ResponsiblePerson>
                  elements={DROPDOWN_ITEMS}
                  element={row.original as ResponsiblePerson}
                  callbackOnClick={handleClose}
                />
              </Popover>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <Page>
      <div className="responsible-persons">
        <div className="responsible-persons__header">
          <h1>Responsible Persons</h1>
          <Button
            variant="contained"
            startIcon={<Icon icon={IconType.Plus} />}
            onClick={() => store.dispatch(changeModal(MODALS.ADD_RP_FORM))}
          >
            Add a new responsible person
          </Button>
        </div>
        <SearchBar onChange={(e) => setSearch(e.target.value)} />

        <Card className="responsible-persons__table-container">
          <Table<ResponsiblePersonsTableProps>
            columns={columns}
            data={persons}
          />
        </Card>
      </div>
    </Page>
  );
};

export default ResponsiblePersons;
