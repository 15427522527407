import { useFormikContext } from 'formik';

import Card from 'src/shared/components/Card/Card';
import FileUploader from 'src/shared/components/FileUploader/FileUploader';
import { FormField } from 'src/shared/models';

import { ReactComponent as Logo } from '../../../../layouts/DocumentsUploadLayout/images/logo.svg';

import './UploaderCard.scss';

interface UploaderCardProps {
  title: string;
  fileFormat: string;
  fieldName: string;
  uploaded?: boolean;
  maxSize?: number;
}

const MAX_SIZE = 25;

const UploaderCard = ({
  title,
  fileFormat,
  fieldName,
  uploaded,
  maxSize = MAX_SIZE,
}: UploaderCardProps) => {
  const { errors, submitCount } = useFormikContext<FormField>();

  return (
    <Card
      className={`uploader-card ${
        submitCount && errors[fieldName] ? 'uploader-card_error' : ''
      }`}
    >
      <div className="uploader-card__header">
        <div>
          <Logo className="uploader-card-header__logo" />
        </div>
        <div>
          <p className="uploader-card-header__title">{title}</p>
          <p className="uploader-card-header__subtitle">
            Select and upload the file of your choice
          </p>
        </div>
      </div>
      <div className="uploader-card__main">
        <FileUploader
          fileTypes={[fileFormat]}
          fieldName={fieldName}
          maxSize={maxSize}
          hideUploadArea={false}
          key={fieldName}
        />
        {uploaded ? (
          <p className="uploader-card__text_uploaded">
            The file has already been uploaded
          </p>
        ) : null}
      </div>
    </Card>
  );
};

export default UploaderCard;
