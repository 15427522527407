import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { paths } from 'src/app/routes';
import Modal from 'src/shared/components/Modal/Modal';
import { ErrorResponse } from 'src/shared/models';

import { useChangeGUESTApplicationStatusMutation } from 'src/features/cocRequests/api/cocRequestsApi';
import { GUESTApplicationStatus } from 'src/features/cocRequests/models';
import { COC_REQUEST } from 'src/features/cocRequests/slices/cocRequestsSlice';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State, store } from 'src/features/store/store';
import { setRequestId } from 'src/features/testimonials/slices/testimonialSlice';

const ApproveCoC = () => {
  const requestId = useSelector((s: State) => s[COC_REQUEST].requestId);

  const navigate = useNavigate();
  const [approveRequest, result] = useChangeGUESTApplicationStatusMutation();

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setRequestId(null));
  };

  useEffect(() => {
    if (result.isError) {
      closeModal();
      toast.error(`Error: ${(result.error as ErrorResponse).data.error}`, {
        containerId: CONTAINER_ID_ACTION,
      });
    }
    if (result.isSuccess) {
      closeModal();
      navigate(paths.cocRequests);
      toast.success('GUEST Approved Successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, result]);

  return (
    <Modal
      open={true}
      title="Approve the CoC Application"
      text="Are you sure you want to approve this CoC Application?"
      confirmButtonText="Approve Application"
      onBackClick={closeModal}
      onConfirmClick={() =>
        requestId &&
        approveRequest({
          uid: requestId,
          status: GUESTApplicationStatus.APPROVED,
        })
      }
    />
  );
};

export default ApproveCoC;
