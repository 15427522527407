import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import DatePicker from 'src/shared/components/DatePicker/DatePicker';
import Input from 'src/shared/components/Input/Input';
import Select from 'src/shared/components/Select/Select';
import { ErrorResponse } from 'src/shared/models';

import { UserRoles } from 'src/features/auth/models';
import {
  useCreateUserProfileMutation,
  useUpdateUserProfileMutation,
} from 'src/features/companies/api/companiesApi';
import { CompanyUser as CompanyUserType } from 'src/features/companies/models';
import { COMPANIES } from 'src/features/companies/slices/companiesSlice';
import { useCheckError } from 'src/features/hooks';
import {
  useCreateIssuerForUserMutation,
  useLazyGetIssuersByCompanyQuery,
} from 'src/features/issuers/api/issuersApi';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State, store } from 'src/features/store/store';
import {
  useCreateTemplateForUserMutation,
  useLazyGetTemplatesByCompanyQuery,
} from 'src/features/templates/api/templatesApi';

import './CompanyUser.scss';

export type CompanyInfoTypes = 'CREATE' | 'EDIT';

type CompanyInfoProps = {
  type?: CompanyInfoTypes;
};

type SelectValue = {
  value: string;
  label: string;
};

const ROLES_OPTIONS: { label: string; value: string }[] = Object.keys(
  UserRoles
).map((role) => {
  return {
    label: role,
    value: role,
  };
});

type CompanyUserValuesType = Pick<
  CompanyUserType,
  | 'userUid'
  | 'firstName'
  | 'lastName'
  | 'companyUid'
  | 'email'
  | 'phone'
  | 'roles'
> & {
  birthDate: string;
  password: string;
  createdAt?: string;
};

const INITIAL_VALUES: CompanyUserValuesType = {
  userUid: '',
  companyUid: '',
  email: '',
  roles: [],
  password: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  phone: '',
};

const addCompanyUserValidation = (type: CompanyInfoTypes) => {
  const object = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .required('This field is required'),
    password: Yup.string().required('This field is required'),
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    roles: Yup.array().min(1, 'This field is required'),
  });
  return type === 'EDIT' ? object.omit(['password']) : object;
};

const CompanyUser = ({ type = 'CREATE' }: CompanyInfoProps) => {
  const { id } = useParams();
  const [issuers, setIssuers] = useState<SelectValue[]>([]);
  const [templates, setTemplates] = useState<SelectValue[]>([]);
  const [createUser, creatingResult] = useCreateUserProfileMutation();
  const [updateUser, updatingResult] = useUpdateUserProfileMutation();
  const closeModal = () => {
    store.dispatch(changeModal(null));
  };
  const currentUser = useSelector((s: State) => s[COMPANIES].user);
  const [getCompanyIssuers, companyIssuers] = useLazyGetIssuersByCompanyQuery();
  const [createIssuer, createIssuerResult] = useCreateIssuerForUserMutation();
  const [getCompanyTemplates, companyTemplates] =
    useLazyGetTemplatesByCompanyQuery();
  const [createTemplate, createTemplateResult] =
    useCreateTemplateForUserMutation();

  const issuersOptions = useMemo(() => {
    if (id && companyIssuers.isUninitialized) {
      getCompanyIssuers(id);
    }
    return companyIssuers.data
      ? companyIssuers.data.data.map((issuer) => {
          return {
            value: issuer.uid,
            label: issuer.name,
          };
        })
      : [];
  }, [
    companyIssuers.data,
    companyIssuers.isUninitialized,
    getCompanyIssuers,
    id,
  ]);

  const templatesOptions = useMemo(() => {
    if (id && companyTemplates.isUninitialized) {
      getCompanyTemplates(id);
    }
    return companyTemplates.data
      ? companyTemplates.data.data.map((template) => {
          return {
            value: template.uid,
            label: template.name,
          };
        })
      : [];
  }, [
    companyTemplates.data,
    companyTemplates.isUninitialized,
    getCompanyTemplates,
    id,
  ]);

  const getBirthdate = (date: string) =>
    !date
      ? dayjs(new Date()).format('YYYY-MM-DD')
      : dayjs(date).format('YYYY-MM-DD');

  const onCreate = async (values: CompanyUserValuesType) => {
    const birthDate = getBirthdate(values.birthDate);
    const userUid = uuidv4();
    id &&
      (await createUser({
        ...values,
        userUid,
        companyUid: id,
        birthDate,
      }));
    const issuersRequests = issuers.map((value) =>
      createIssuer({
        issuerUid: value.value,
        userUid,
      })
    );
    const templatesRequests = templates.map((value) =>
      createTemplate({
        templateUid: value.value,
        userUid,
      })
    );
    Promise.all(issuersRequests)
      .then(() => Promise.all(templatesRequests))
      .catch((error) =>
        toast.error(`Creating error: ${error}`, {
          containerId: CONTAINER_ID_ACTION,
        })
      );
  };

  const onUpdate = (values: CompanyUserValuesType) => {
    const birthDate = getBirthdate(values.birthDate);
    updateUser({
      ...values,
      birthDate,
    });
  };

  useEffect(() => {
    if (creatingResult.isSuccess) {
      closeModal();
      toast.success('Created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [creatingResult.isSuccess]);

  useEffect(() => {
    if (createIssuerResult.isSuccess) {
      toast.success('Issuer assigned successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [createIssuerResult.isSuccess]);

  useEffect(() => {
    if (createTemplateResult.isSuccess) {
      toast.success('Template assigned successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [createTemplateResult.isSuccess]);

  useEffect(() => {
    if (updatingResult.isSuccess) {
      closeModal();
      toast.success('Updated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [updatingResult.isSuccess]);

  useCheckError(
    creatingResult.isError,
    `Creating error: ${(creatingResult.error as ErrorResponse)?.data.message}`
  );

  useCheckError(
    updatingResult.isError,
    `Updating error: ${(updatingResult.error as ErrorResponse)?.data.message}`
  );

  useCheckError(
    createIssuerResult.isError,
    `Assign issuer error: ${
      (createIssuerResult.error as ErrorResponse)?.data.message
    }`
  );

  useCheckError(
    createTemplateResult.isError,
    `Assign template error: ${
      (createTemplateResult.error as ErrorResponse)?.data.message
    }`
  );

  const initialFormValues = useMemo<CompanyUserValuesType>(() => {
    return type === 'EDIT' && currentUser
      ? {
          ...currentUser,
          password: '',
        }
      : INITIAL_VALUES;
  }, [currentUser, type]);

  return (
    <Dialog
      open={true}
      maxWidth="lg"
      fullWidth
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      <div className="modal__header">
        <DialogTitle maxWidth="1000px">
          {type === 'EDIT' ? 'Change user' : 'Create user'}
        </DialogTitle>
      </div>

      <Formik
        initialValues={initialFormValues}
        onSubmit={type === 'EDIT' ? onUpdate : onCreate}
        validationSchema={addCompanyUserValidation(type)}
      >
        {({ values }) => (
          <Form className="add-recipient-form">
            <DialogContent>
              <div className="add-recipient-form__inputs-container">
                <Input
                  name="email"
                  label="Email"
                  placeholder="Email"
                  required
                />
                <Input
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                  required
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  required
                />
              </div>
              <div className="add-recipient-form__inputs-container company-user-form__inputs-container">
                <Select
                  name="roles"
                  label="Roles"
                  options={ROLES_OPTIONS}
                  placeholder="Choose one"
                  required
                  multiple
                />
                {type === 'EDIT' ? null : (
                  <Input
                    name="password"
                    label="Password"
                    placeholder="Password"
                    className="input-container_short"
                    required
                  />
                )}
              </div>
              {type === 'CREATE' &&
              values.roles.includes(UserRoles.ISSUER_BASIC) ? (
                <div className="company-user-form__issuer-basic-container">
                  <div className="company-user-form__issuers-container">
                    <label className="input__label" htmlFor="issuers">
                      Issuers
                    </label>
                    <ReactSelect
                      value={issuers}
                      onChange={(value) => setIssuers([...value])}
                      options={issuersOptions}
                      isMulti
                      name="issuers"
                    />
                  </div>
                  <div className="company-user-form__templates-container">
                    <label className="input__label" htmlFor="templates">
                      Templates
                    </label>
                    <ReactSelect
                      value={templates}
                      onChange={(value) => setTemplates([...value])}
                      options={templatesOptions}
                      isMulti
                      name="templates"
                    />
                  </div>
                </div>
              ) : null}
              <div className="add-recipient-form__inputs-container">
                <DatePicker
                  name="birthDate"
                  label="Birthdate"
                  format="YYYY-MM-DD"
                />
                <Input name="phone" label="Phone" placeholder="Phone" />
              </div>
            </DialogContent>

            <DialogActions>
              <Button onClick={closeModal} color="secondary">
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {type === 'EDIT' ? 'Change' : 'Create'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CompanyUser;
