import { useFormikContext } from 'formik';

import TextError from 'src/shared/components/TextError/TextError';
import { FormField } from 'src/shared/models';

interface FieldsValidationErrorProps {
  isVisible?: boolean;
}

const FieldsValidationError = ({ isVisible }: FieldsValidationErrorProps) => {
  const { isValid, isValidating, submitCount } = useFormikContext<FormField>();

  return (submitCount && !isValid && !isValidating) ||
    (submitCount && isVisible) ? (
    <TextError>Please fill all required fields</TextError>
  ) : null;
};

export default FieldsValidationError;
