import { ErrorMessage, Field, FieldAttributes } from 'formik';

import TextError from '../TextError/TextError';

type InputProps = FieldAttributes<unknown> & {
  label: string;
  name: string;
  info?: string;
  className?: string;
  required?: boolean;
};

const TextArea = ({
  label,
  name,
  info,
  className,
  required,
  ...rest
}: InputProps) => {
  return (
    <div className={`form-control input ${className ?? ''}`}>
      <label className="input__label" htmlFor={name}>
        {label}
        {required ? <span className="input__label_required">*</span> : null}
      </label>
      <Field
        className="form-input"
        as="textarea"
        rows="3"
        id={name}
        name={name}
        {...rest}
      />
      {info ? <p className="input__info">{info}</p> : null}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default TextArea;
