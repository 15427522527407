import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { paths } from 'src/app/routes';
import Modal from 'src/shared/components/Modal/Modal';
import { ErrorResponse } from 'src/shared/models';

import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State, store } from 'src/features/store/store';
import { useChangeRequestStatusMutation } from 'src/features/testimonials/api/testimonialsApi';
import { TestimonialRequestStatus } from 'src/features/testimonials/models';
import {
  setRequestId,
  TESTIMONIAL,
} from 'src/features/testimonials/slices/testimonialSlice';

import { ReactComponent as ApproveIcon } from './images/thumb-up-icon.svg';

const ValidateSST = () => {
  const requestId = useSelector((s: State) => s[TESTIMONIAL].requestId);
  const navigate = useNavigate();
  const [validateRequest, result] = useChangeRequestStatusMutation();

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setRequestId(null));
  };

  useEffect(() => {
    if (result.isError) {
      closeModal();
      toast.error(`Error: ${(result.error as ErrorResponse).data.error}`, {
        containerId: CONTAINER_ID_ACTION,
      });
    }
    if (result.isSuccess) {
      closeModal();
      navigate(paths.requests);
      toast.success('SST Approved Successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, result]);

  return (
    <Modal
      open={true}
      title="Validate the SST"
      text="Are you sure you want to validate this SST?"
      confirmButtonText="Validate the SST"
      onBackClick={closeModal}
      onConfirmClick={() =>
        requestId &&
        validateRequest({
          uid: requestId,
          status: TestimonialRequestStatus.VALIDATED_PYA,
        })
      }
      icon={<ApproveIcon />}
      loading={result.isLoading}
    />
  );
};

export default ValidateSST;
