import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

import Spinner from '../Spinner/Spinner';

import './Modal.scss';

type ModalProps = {
  open: boolean;
  title: string;
  text?: string;
  content?: JSX.Element;
  inputField?: boolean;
  hideConfirmButton?: boolean;
  hideBackButton?: boolean;
  confirmButtonText?: string;
  backButtonText?: string;
  onBackClick?: () => void;
  onConfirmClick?: (confirmMessage?: string) => void;
  color?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
  icon?: JSX.Element;
  loading?: boolean;
  className?: string;
};

const Modal = ({
  open,
  title,
  text,
  content,
  inputField,
  hideConfirmButton = false,
  hideBackButton = false,
  confirmButtonText,
  backButtonText,
  onBackClick,
  onConfirmClick,
  color,
  icon,
  loading,
  className,
}: ModalProps) => {
  const [textValue, setTextValue] = useState<string>('');

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <div className={className ?? ''}>
        <div className={`modal__header ${icon ? 'modal__header_icon' : ''}`}>
          {icon ?? null}
          <DialogTitle maxWidth="500px">
            {title}
            {text ? (
              <DialogContentText id="dialog-description">
                {text}
              </DialogContentText>
            ) : null}
          </DialogTitle>
        </div>
        <DialogContent>
          {content ?? null}
          {inputField ? (
            <TextField
              placeholder="Type your reason here..."
              fullWidth
              multiline
              rows={3}
              value={textValue}
              onChange={(event) => setTextValue(event.target.value)}
              inputProps={{ maxLength: 500 }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          {hideBackButton ? null : (
            <Button onClick={onBackClick} color="secondary">
              {backButtonText ?? 'Back'}
            </Button>
          )}
          {hideConfirmButton ? null : loading ? (
            <Spinner />
          ) : (
            <Button
              variant="contained"
              onClick={
                onConfirmClick ? () => onConfirmClick(textValue) : undefined
              }
              color={color}
              autoFocus
            >
              {confirmButtonText ?? 'Confirm'}
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Modal;
