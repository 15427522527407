import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { Form, Formik } from 'formik';
import pLimit from 'p-limit';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Card from 'src/shared/components/Card/Card';
import Spinner from 'src/shared/components/Spinner/Spinner';
import { ReactComponent as Logo } from 'src/shared/images/logo-black.svg';
import { getKey } from 'src/shared/utils';

import {
  useChangeGUESTApplicationStatusMutation,
  useGetGUESTApplicationByIdQuery,
  useGetGUESTApplicationDocumentsQuery,
  useUploadGUESTApplicationDocumentMutation,
  useUploadGUESTApplicationImageMutation,
} from 'src/features/cocRequests/api/cocRequestsApi';
import {
  ApplicationDocumentType,
  ApplicationRequirementsType,
  CoCRequestType,
  documentsTypes,
  GUESTApplicationStatus,
} from 'src/features/cocRequests/models';
import { useCheckError } from 'src/features/hooks';

import FieldsValidationError from './components/FieldsValidationError/FieldsValidationError';
import UploaderCard from './components/UploaderCard/UploaderCard';

import './UploadGUESTDocuments.scss';

const DOCS_LIMIT = 10;
const CONTACT_EMAIL = 'support@n-cap.net';

interface DocumentValues {
  [documentName: string]: File | null;
}

const UploadGUESTDocuments = (): JSX.Element => {
  const { id } = useParams();
  const params = useSearchParams();
  const authToken = params[0].get('token') as string;

  const { data: application } = useGetGUESTApplicationByIdQuery(
    { applicationUid: id as string, authToken: authToken },
    {
      skip: !id,
    }
  );
  const limit = pLimit(DOCS_LIMIT);
  const [uploadDocument, uploadDocumentResult] =
    useUploadGUESTApplicationDocumentMutation();
  const [uploadImage, uploadImageResult] =
    useUploadGUESTApplicationImageMutation();

  const { data: documents } = useGetGUESTApplicationDocumentsQuery(
    { applicationUid: id as string, authToken: authToken },
    {
      skip: !id,
    }
  );

  const [changeRequestStatus, changeRequestStatusResult] =
    useChangeGUESTApplicationStatusMutation();

  const [isError, setIsError] = useState<boolean>(false);
  const [isDocsError, setIsDocsError] = useState<boolean>(false);
  const [isSTCWError, setIsSTCWError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isPassportPictureUploaded, setIsPassportPictureUploaded] =
    useState<boolean>(false);

  const type = application?.data.type
    ? CoCRequestType[
        application?.data.type as unknown as keyof typeof CoCRequestType
      ]
    : CoCRequestType.HEAD_HOUSEKEEPING;
  const status =
    application?.data.status ??
    GUESTApplicationStatus.APPLICATION_PAYMENT_SUCCESS;

  const documentsRequired = documentsTypes[type]
    .coursesCompletedRequired as ApplicationRequirementsType;
  const stwcCoursesRequired = documentsTypes[type].stwcCoursesRequired;

  const isStatusValid = () => {
    return (
      status !== undefined &&
      (status === GUESTApplicationStatus.APPLICATION_PAYMENT_SUCCESS ||
        status === GUESTApplicationStatus.DOCUMENTS_REVISION)
    );
  };

  const initialValues: object = useMemo(() => {
    const docs = documents?.data.length
      ? documents.data.reduce((object, document) => {
          return {
            ...object,
            [document.documentType as unknown as keyof typeof ApplicationDocumentType]:
              document,
          };
        }, {})
      : {};
    const passportPicture = isPassportPictureUploaded
      ? {
          PASSPORT_PHOTO: 'uploaded',
        }
      : {};
    return { ...docs, ...passportPicture };
  }, [documents?.data, isPassportPictureUploaded]);

  const validationSchema = useMemo(() => {
    let courses = {};
    let docs = {};
    let stwcCourses = {};

    const baseValidation = {
      [ApplicationDocumentType.CV]: Yup.mixed().required(
        'This field is required'
      ),
      [ApplicationDocumentType.PASSPORT]: Yup.mixed().required(
        'This field is required'
      ),
      [ApplicationDocumentType.PASSPORT_PHOTO]: Yup.mixed().required(
        'This field is required'
      ),
    };
    documentsTypes[type].requiredForApplication.forEach((courseName) => {
      const key = getKey(ApplicationDocumentType, courseName);
      courses = {
        ...courses,
        [key]: Yup.mixed().required('This field is required'),
      };
    });

    const required = documentsTypes[type]
      .coursesCompletedRequired as ApplicationRequirementsType;
    if (required === 'ALL_REQUIRED') {
      documentsTypes[type].coursesCompleted.forEach((docName) => {
        const key = getKey(ApplicationDocumentType, docName);

        docs = {
          ...docs,
          [key]: Yup.mixed().required('This field is required'),
        };
      });
    }

    if (stwcCoursesRequired === 5) {
      for (let i = 0; i < stwcCoursesRequired; i++) {
        stwcCourses = {
          ...stwcCourses,
          [`STCW_${i + 1}`]: Yup.mixed().required('This field is required'),
        };
      }
    }

    return Yup.object({
      ...baseValidation,
      ...docs,
      ...courses,
      ...stwcCourses,
    });
  }, [stwcCoursesRequired, type]);

  const getSTWCCources = () => {
    const array = [];
    for (let i = 0; i < 5; i++) {
      const title = `STCW-${i + 1}`;
      const name = `STCW_${i + 1}`;
      const uploaded = Object.keys(initialValues).includes(name);
      array.push(
        <>
          <UploaderCard
            title={title}
            fileFormat="PDF"
            key={i + name}
            fieldName={name}
            uploaded={uploaded}
          />
        </>
      );
    }
    return array;
  };

  const checkRequired = (values: object) => {
    const required = documentsTypes[type]
      .coursesCompletedRequired as ApplicationRequirementsType;
    const requirements = documentsTypes[type].coursesCompleted;
    if (typeof required === 'number') {
      return (
        requirements.reduce((currentCount, currentRequirement) => {
          return (values as DocumentValues)[
            getKey(ApplicationDocumentType, currentRequirement)
          ]
            ? currentCount + 1
            : currentCount;
        }, 0) >= required
      );
    }
  };

  const checkDocsRequired = (values: object) => {
    const requirements = documentsTypes[type].oneOfDocsRequired;
    if (requirements.length === 0) {
      return false;
    }
    return !requirements.some((elem) => {
      return (values as DocumentValues)[getKey(ApplicationDocumentType, elem)]
        ? true
        : false;
    });
  };

  const checkSTCW = (values: object) => {
    if (stwcCoursesRequired === 2) {
      let count = 0;
      for (let i = 0; i < 5; i++) {
        const name = `STCW_${i + 1}`;
        if (
          Object.keys(values).includes(name) &&
          (values as DocumentValues)[name] !== null
        ) {
          count++;
        }
      }
      if (count < stwcCoursesRequired) {
        setIsSTCWError(true);
      } else {
        setIsSTCWError(false);
      }
    }
  };

  const submitDocuments = (values: object) => {
    if (!isError && !isDocsError && !isSTCWError && id) {
      const requests = Object.keys(values).map((key) => {
        const file = (values as DocumentValues)[key];
        if (file !== null && file instanceof File) {
          const formData = new FormData();
          formData.append('file', file, file.name);
          formData.append('type', key);

          return limit(() => {
            uploadDocument({
              applicationUid: id,
              uid: uuidv4(),
              document: formData,
              authToken: authToken,
            });
          });
        }
      });

      const imageValue = (values as DocumentValues)[
        ApplicationDocumentType.PASSPORT_PHOTO
      ];
      if (imageValue !== null && imageValue instanceof File) {
        const imageData = new FormData();
        imageData.append('file', imageValue, imageValue.name);
        uploadImage({
          applicationUid: id,
          uid: uuidv4(),
          document: imageData,
          authToken: authToken,
        });
      }

      Promise.allSettled(requests).catch((error) => {
        console.log(error);
      });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/guest-application/${id}/image`, {
      headers: new Headers({
        'form-auth': authToken,
        'form-object': id as string,
      }),
    })
      .then((res) => setIsPassportPictureUploaded(res.status === 200))
      .catch((error) => console.log(error));
  }, [authToken, id]);

  useEffect(() => {
    if (
      !uploadDocumentResult.isUninitialized &&
      uploadDocumentResult.isSuccess &&
      !uploadImageResult.isUninitialized &&
      uploadImageResult.isSuccess
    ) {
      id &&
        changeRequestStatus({
          uid: id,
          status: GUESTApplicationStatus.DOCUMENTS_UPLOADED,
          authToken: authToken,
        });
    } else if (
      uploadDocumentResult.isUninitialized &&
      !uploadDocumentResult.isSuccess &&
      !uploadImageResult.isUninitialized &&
      uploadImageResult.isSuccess
    ) {
      id &&
        changeRequestStatus({
          uid: id,
          status: GUESTApplicationStatus.DOCUMENTS_UPLOADED,
          authToken: authToken,
        });
    } else if (
      !uploadDocumentResult.isUninitialized &&
      uploadDocumentResult.isSuccess &&
      uploadImageResult.isUninitialized &&
      !uploadImageResult.isSuccess
    ) {
      id &&
        changeRequestStatus({
          uid: id,
          status: GUESTApplicationStatus.DOCUMENTS_UPLOADED,
          authToken: authToken,
        });
    }
  }, [
    authToken,
    changeRequestStatus,
    id,
    uploadDocumentResult.isSuccess,
    uploadDocumentResult.isUninitialized,
    uploadImageResult.isSuccess,
    uploadImageResult.isUninitialized,
  ]);

  useEffect(() => {
    if (changeRequestStatusResult.isSuccess) {
      setIsSuccess(true);
    }
  }, [changeRequestStatusResult.isSuccess]);

  useCheckError(
    changeRequestStatusResult.isError,
    'Error changing the application status'
  );
  useCheckError(
    uploadDocumentResult.isError,
    'Error uploading the application documents'
  );
  useCheckError(
    uploadImageResult.isError,
    'Error uploading the passport photo'
  );

  const successPage = (
    <div className="upload-guest-documents-error">
      <Logo className="upload-guest-documents-error__logo" />
      <h1 className="upload-guest-documents-error__header">
        Your documents have been submitted
      </h1>
      <p className="upload-guest-documents-error__text">
        A GUEST administrator will review your submission. You’ll be notified on
        your N-CAP Wallet once completed
      </p>
      <p className="upload-guest-documents-error__text_contact">
        For any further queries, please contact our support team:
        <a href={`mailto:${CONTACT_EMAIL}`}>{`${CONTACT_EMAIL}`}</a>
      </p>
    </div>
  );

  const uploadForm = isSuccess ? (
    successPage
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submitDocuments(values)}
      validationSchema={validationSchema}
      validate={(values: object) => {
        const res = checkRequired(values);
        setIsError(res !== undefined ? !res : false);
        const docsCheck = checkDocsRequired(values);
        setIsDocsError(docsCheck);
        checkSTCW(values);
      }}
      enableReinitialize
    >
      {({ submitCount }) => (
        <Form>
          <div className="upload-guest-documents">
            <Card className="upload-guest-documents__card">
              <h2>
                {`Guest CoC ${
                  application?.data.type
                    ? CoCRequestType[application?.data.type]
                    : ''
                }`}
              </h2>
              <p>
                All documents are required to be in PDF format, except personal
                photograph of the candidate. Documents must always include the
                candidate´s full name
              </p>
            </Card>

            <Card className="upload-guest-documents__card">
              <h2>Required Personal Documents</h2>
              <p>The below documents are required to confirm submission</p>

              <div className="upload-guest-documents__list">
                <UploaderCard
                  title="Upload Curriculum Vitae (CV)"
                  fileFormat="PDF"
                  fieldName={ApplicationDocumentType.CV}
                  uploaded={Object.keys(initialValues).includes(
                    ApplicationDocumentType.CV
                  )}
                  key={ApplicationDocumentType.CV}
                />
                <UploaderCard
                  title="Upload Passport"
                  fileFormat="PDF"
                  fieldName={ApplicationDocumentType.PASSPORT}
                  uploaded={Object.keys(initialValues).includes(
                    ApplicationDocumentType.PASSPORT
                  )}
                  key={ApplicationDocumentType.PASSPORT}
                />
                <UploaderCard
                  title="Upload Passport Style Photo"
                  fileFormat="PNG"
                  fieldName={ApplicationDocumentType.PASSPORT_PHOTO}
                  key={ApplicationDocumentType.PASSPORT_PHOTO}
                  maxSize={10}
                  uploaded={isPassportPictureUploaded}
                />
              </div>
            </Card>

            <Card className="upload-guest-documents__card">
              <h2>Required for Application</h2>

              <p>All below documents are required to confirm submission</p>
              <div className="upload-guest-documents__list">
                {type && isStatusValid()
                  ? documentsTypes[type].requiredForApplication.map(
                      (doc, index) => {
                        const key = getKey(ApplicationDocumentType, doc);
                        const uploaded =
                          Object.keys(initialValues).includes(key);
                        return (
                          <>
                            <UploaderCard
                              title={doc}
                              fileFormat="PDF"
                              key={index + key}
                              fieldName={key}
                              uploaded={uploaded}
                            />
                          </>
                        );
                      }
                    )
                  : null}
              </div>

              {documentsTypes[type].oneOfDocsRequired.length > 0 ? (
                <>
                  <p
                    className={`${
                      submitCount && isDocsError
                        ? 'upload-guest-documents__error-message'
                        : ''
                    }`}
                  >
                    At least 1 of the below documents is required to confirm
                    submission
                  </p>
                  <div className="upload-guest-documents__list">
                    {type && isStatusValid()
                      ? documentsTypes[type].oneOfDocsRequired.map(
                          (doc, index) => {
                            const key = getKey(ApplicationDocumentType, doc);
                            const uploaded =
                              Object.keys(initialValues).includes(key);
                            return (
                              <>
                                <UploaderCard
                                  title={doc}
                                  fileFormat="PDF"
                                  key={index + key}
                                  fieldName={key}
                                  uploaded={uploaded}
                                />
                              </>
                            );
                          }
                        )
                      : null}
                  </div>
                </>
              ) : null}
            </Card>

            <Card className="upload-guest-documents__card">
              <h2>Courses Completed</h2>
              <p
                className={`${
                  submitCount && isError
                    ? 'upload-guest-documents__error-message'
                    : ''
                }`}
              >
                {documentsRequired === 'ALL_REQUIRED'
                  ? 'All below documents are required to confirm submission'
                  : `At least ${documentsRequired} of the below documents is required to confirm submission`}
              </p>
              <div className="upload-guest-documents__list">
                {type && isStatusValid()
                  ? documentsTypes[type].coursesCompleted.map((doc, index) => {
                      const key = getKey(ApplicationDocumentType, doc);
                      const uploaded = Object.keys(initialValues).includes(key);

                      return (
                        <>
                          <UploaderCard
                            title={doc}
                            fileFormat="PDF"
                            key={index + key}
                            fieldName={key}
                            uploaded={uploaded}
                          />
                        </>
                      );
                    })
                  : null}
              </div>
            </Card>

            {!stwcCoursesRequired ? null : (
              <Card className="upload-guest-documents__card">
                <h2>Required STCW certificates</h2>
                <p
                  className={`${
                    submitCount && isSTCWError
                      ? 'upload-guest-documents__error-message'
                      : ''
                  }`}
                >
                  {stwcCoursesRequired === 5
                    ? 'All below documents are required to confirm submission'
                    : 'At least 2 of the below documents is required to confirm submission'}
                </p>

                <div className="upload-guest-documents__list">
                  {type && isStatusValid() ? getSTWCCources() : null}
                </div>
              </Card>
            )}

            <Card className="upload-guest-documents__card">
              <div className="upload-guest-documents__supplied-by">
                <FormControlLabel
                  control={<Checkbox />}
                  label="I declare that the data contained in this application is,
                    to the best of my knowledge, true and complete.
                    I also declare that the documents are genuine and signed by the persons whose names appear on the them.
                    I consent to any processing of the data contained in this application by the PYA and GUEST Administration 
                    (including any processing necessary to establish the authenticity and validity of the issued certificate)*"
                  name="Agreement"
                  checked={isConfirmed}
                  onChange={() => setIsConfirmed((prev) => !prev)}
                  className="upload-guest-documents__agreement"
                />
              </div>

              {uploadDocumentResult.isLoading ? (
                <Spinner />
              ) : (
                <div className="upload-guest-documents__submit-button">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isConfirmed}
                  >
                    Submit Documents
                  </Button>
                  <FieldsValidationError isVisible={isError} />
                </div>
              )}
            </Card>
          </div>
        </Form>
      )}
    </Formik>
  );

  const errorPage = (
    <div className="upload-guest-documents-error">
      <Logo className="upload-guest-documents-error__logo" />
      <h1 className="upload-guest-documents-error__header">That’s an error</h1>
      <p className="upload-guest-documents-error__text">
        This application form has been already submitted or it’s been closed for
        modifications
      </p>
      <p className="upload-guest-documents-error__text_contact">
        Contact the GUEST administrator in case of any doubts:
        <a href={`mailto:${CONTACT_EMAIL}`}>{`${CONTACT_EMAIL}`}</a>
      </p>
    </div>
  );

  return isStatusValid() || isSuccess ? uploadForm : errorPage;
};

export default UploadGUESTDocuments;
