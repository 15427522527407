import { useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Input from 'src/shared/components/Input/Input';
import Select from 'src/shared/components/Select/Select';
import { ErrorResponse } from 'src/shared/models';
import { countries } from 'src/shared/utils';

import { useCreateCompanyMutation } from 'src/features/companies/api/companiesApi';
import { Company } from 'src/features/companies/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { store } from 'src/features/store/store';

const NATIONALITY_OPTIONS: { label: string; value: string }[] = countries;

const INITIAL_VALUES = {
  uid: '',
  companyName: '',
  email: '',
  vatNumber: '',
  address: '',
  city: '',
  zipCode: '',
  country: '',
  telephone: '',
};

const addCompanyValidation = Yup.object().shape({
  companyName: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('This field is required'),
  country: Yup.string().required('This field is required'),
});

const AddCompany = () => {
  const [createCompany, creatingResult] = useCreateCompanyMutation();
  const closeModal = () => {
    store.dispatch(changeModal(null));
  };

  const onSubmit = (values: Company) => {
    createCompany({ ...values, companyUid: uuidv4() });
  };

  useEffect(() => {
    if (creatingResult.isSuccess) {
      closeModal();
      toast.success('Created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [creatingResult.isSuccess]);

  useEffect(() => {
    if (creatingResult.isError) {
      toast.error(
        `Creating error: ${
          (creatingResult.error as ErrorResponse).data.message
        }`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
  }, [creatingResult.error, creatingResult.isError]);

  return (
    <Dialog
      open={true}
      maxWidth="lg"
      fullWidth
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      <div className="modal__header">
        <DialogTitle maxWidth="1000px">Create company</DialogTitle>
      </div>

      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validationSchema={addCompanyValidation}
      >
        <Form className="add-recipient-form">
          <DialogContent>
            <div className="add-recipient-form__inputs-container">
              <Input
                name="companyName"
                label="Company Name"
                placeholder="Company Name"
                required
              />
              <Input name="email" label="Email" placeholder="Email" required />
              <Input
                name="vatNumber"
                label="VAT number"
                placeholder="VAT number"
              />
            </div>
            <div className="add-recipient-form__inputs-container">
              <Input name="address" label="Address" placeholder="Address" />
              <Input name="city" label="City" placeholder="City" />
              <Select
                name="country"
                label="Country"
                options={NATIONALITY_OPTIONS}
                placeholder="Choose one"
                required
              />
            </div>
            <div className="add-recipient-form__inputs-container">
              <Input name="zipCode" label="ZIP code" placeholder="ZIP code" />
              <Input
                name="telephone"
                label="Telephone"
                placeholder="Telephone"
              />
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={closeModal} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Create
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddCompany;
