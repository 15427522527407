import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';
import Modal from 'src/shared/components/Modal/Modal';

import {
  clearCredentialDetails,
  CREDENTIALS,
} from 'src/features/credentials/slices/credentialsSlice';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { State, store } from 'src/features/store/store';

import './CredentialsCreation.scss';

const CredentialsCreation = () => {
  const navigate = useNavigate();
  const result = useSelector(
    (s: State) => s[CREDENTIALS].credentialsCreatingResult
  );

  const successCreation = (
    <Modal
      open={true}
      title="Credentials creation in progress"
      text={`${result.total - result.failed} out of ${
        result.total
      } credentials created`}
      confirmButtonText="Go to Credentials List"
      hideBackButton
      onConfirmClick={() => {
        store.dispatch(changeModal(null));
        store.dispatch(clearCredentialDetails());
        navigate(paths.credentials);
      }}
      icon={
        <Icon
          icon={IconType.Verify}
          className="credentials-creation__success"
        />
      }
    />
  );

  const failedCreation = (
    <Modal
      open={true}
      title="Credentials creation in progress"
      text={`Failed to create ${result.failed} of ${result.total} credentials`}
      confirmButtonText="Go back"
      hideBackButton
      onConfirmClick={() => {
        store.dispatch(changeModal(null));
      }}
      icon={
        <Icon
          icon={IconType.Warning}
          className="credentials-creation__failed"
        />
      }
    />
  );

  return result.failed > 0 ? failedCreation : successCreation;
};

export default CredentialsCreation;
