import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';
import { ReactComponent as Logo } from 'src/shared/images/logo-white.svg';

import { useGetMyProfileQuery } from 'src/features/auth/api/authApi';

import './AuthLayout.scss';

const AuthLayout = (): JSX.Element => {
  const navigate = useNavigate();

  const { isFetching, isLoading, isSuccess } = useGetMyProfileQuery();

  useEffect(() => {
    if (!isFetching && !isLoading && isSuccess) {
      navigate(paths.dashboard);
    }
  }, [isFetching, isLoading, isSuccess, navigate]);

  return (
    <div className="auth-layout">
      <div className="auth-layout__logo">
        <Logo />
      </div>
      <div className="auth-layout__content">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
