/* eslint-disable react/jsx-one-expression-per-line */
import { useCallback, useMemo, useState } from 'react';
import { FileUploader as Uploader } from 'react-drag-drop-files';
import { useSelector } from 'react-redux';

import dayjs, { Dayjs } from 'dayjs';
import readXlsxFile from 'read-excel-file';
import writeXlsxFile from 'write-excel-file';

import FilePreview from 'src/shared/components/FilePreview/FilePreview';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';
import Modal from 'src/shared/components/Modal/Modal';
import { countries, DATE_FORMAT } from 'src/shared/utils';

import { CredentialRecipientDetails } from 'src/features/credentials/models';
import { addCredentialDetails } from 'src/features/credentials/slices/credentialsSlice';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { State, store } from 'src/features/store/store';
import {
  DISPLAY_NAME_FIELDNAME,
  LabelTypes,
} from 'src/features/templates/models';
import { TEMPLATES } from 'src/features/templates/slices/templatesSlice';

import './ImportSpreadsheet.scss';

const FILE_TYPES = ['XLSX'];
const MAX_SIZE = 6;

interface ValidationError {
  error: string;
  reason?: string;
  row: number;
  column: string;
  value?: unknown;
}

const XLSX_SCHEMA = {
  Name: {
    prop: 'firstName',
    type: String,
    required: true,
  },
  'Last name': {
    prop: 'lastName',
    type: String,
    required: true,
  },
  Email: {
    prop: 'email',
    type: String,
    required: (value: object) => !('secondaryIdentifier' in value),
  },
  'Nationality (Alpha-2 Format)': {
    prop: 'country',
    type: String,
    oneOf: countries.map((country) => country.value),
  },
  'National ID number': {
    prop: 'countryId',
    type: String,
  },
  'Birthdate (dd/mm/yyyy)': {
    prop: 'birthdate',
    type: Date,
  },
  'Internal Identifier': {
    prop: 'secondaryIdentifier',
    type: String,
    required: (value: object) => !('email' in value),
  },
  'Phone Number': {
    prop: 'phone',
    type: String,
  },
  Address: {
    prop: 'address',
    type: String,
  },
  'Issuance Date (dd/mm/yyyy)': {
    prop: 'issuedOn',
    type: Date,
    required: true,
  },
  'Expiration Date (dd/mm/yyyy)': {
    prop: 'expires',
    type: Date,
  },
  'Credential Identifier': {
    prop: 'externalIdentifier',
    type: String,
  },
  Comment: {
    prop: 'comment',
    type: String,
  },
};

enum ContentType {
  UPLOAD,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  VALIDATION_ERROR,
}

const ImportSpreadsheet = () => {
  const paramsNames = useSelector((s: State) => s[TEMPLATES].renderParamsNames);
  const template = useSelector((s: State) => s[TEMPLATES].template);
  const [file, setFile] = useState<File[]>([]);
  const [contentType, setContentType] = useState<ContentType>(
    ContentType.UPLOAD
  );
  const [validationErrors, setErrors] = useState<ValidationError[]>([]);

  const dateFieldsNames = useMemo(
    () =>
      template
        ? Object.keys(template.renderParams).filter(
            (name) =>
              (template.renderParams[name].fieldType as LabelTypes) ===
              LabelTypes.DATE
          )
        : [],
    [template]
  );
  const schema = useMemo(() => {
    const customParams = Object.fromEntries(
      paramsNames
        .filter(
          (name) =>
            template?.renderParams?.[name].fieldType !== LabelTypes.IMAGE &&
            name !== DISPLAY_NAME_FIELDNAME
        )
        .map((name) => {
          const type = template?.renderParams?.[name]?.fieldType || String;
          let tableType;

          switch (type) {
            case LabelTypes.TEXT:
              tableType = String;
              break;
            case LabelTypes.SHORT_TEXT:
              tableType = String;
              break;
            case LabelTypes.NUMBER:
              tableType = Number;
              break;
            case LabelTypes.BOOLEAN:
              tableType = (value: string) => value === 'true';
              break;
            case LabelTypes.DATE:
              tableType = Date;
              break;
            default:
              tableType = String;
          }
          return [
            name,
            {
              prop: name,
              type: tableType,
              required: true,
            },
          ];
        })
    );
    return {
      ...XLSX_SCHEMA,
      ...customParams,
    };
  }, [paramsNames, template?.renderParams]);

  const getTableTemplate = useCallback(async () => {
    const data = Object.keys(XLSX_SCHEMA).map((key) => {
      return {
        value: key,
      };
    });
    const additionalParams = paramsNames
      .filter(
        (name) =>
          template?.renderParams?.[name].fieldType !== LabelTypes.IMAGE &&
          name !== DISPLAY_NAME_FIELDNAME
      )
      .map((name) => {
        return {
          value: name,
        };
      });
    data.push(...additionalParams);

    await writeXlsxFile([data], {
      fileName: 'template-table.xlsx',
    });
  }, [paramsNames, template?.renderParams]);

  const closeModal = () => {
    store.dispatch(changeModal(null));
  };

  const displayValidationErrors = useCallback(() => {
    const errors = validationErrors
      .filter((error) => error.column !== 'Internal Identifier')
      .map((error) => {
        if (error.column === 'Email') {
          return {
            ...error,
            column: 'Email or Internal Identifier',
          };
        }
        return error;
      });

    return errors.map((error, key) => (
      <p
        key={key}
        className="import-spreadsheet__error-text"
      >{`Row ${error.row}: the ${error.column} field is ${error.error}`}</p>
    ));
  }, [validationErrors]);

  const readFile = useCallback(
    (file: File) => {
      readXlsxFile(file, { schema: schema, ignoreEmptyRows: true })
        .then(({ rows, errors }) => {
          if (errors.length === 0) {
            const details: CredentialRecipientDetails[] = (
              rows as unknown as CredentialRecipientDetails[]
            ).map((elem: CredentialRecipientDetails) => {
              const dateValues = Object.fromEntries(
                dateFieldsNames.map((name) => {
                  if (name in elem && elem[name]) {
                    return [
                      name,
                      dayjs(
                        elem[name] as string | number | Date | Dayjs
                      ).format(DATE_FORMAT),
                    ];
                  }
                  return [];
                })
              );
              const displayName =
                template &&
                Object.keys(template.renderParams).includes(
                  DISPLAY_NAME_FIELDNAME
                )
                  ? {
                      [DISPLAY_NAME_FIELDNAME]: `${elem.firstName} ${elem.lastName}`,
                    }
                  : undefined;
              return {
                ...elem,
                ...dateValues,
                ...displayName,
                issuedOn: dayjs(elem.issuedOn).format(DATE_FORMAT),
                birthdate: elem.birthdate
                  ? dayjs(elem.birthdate).format(DATE_FORMAT)
                  : undefined,
                expires: elem.expires
                  ? dayjs(elem.expires).format(DATE_FORMAT)
                  : undefined,
              };
            });
            store.dispatch(addCredentialDetails(details));
            setContentType(ContentType.UPLOAD_SUCCESS);
          } else {
            setErrors(errors);
            setContentType(ContentType.VALIDATION_ERROR);
          }
        })
        .catch(() => setContentType(ContentType.UPLOAD_ERROR));
    },
    [dateFieldsNames, schema, template]
  );

  const fileUploader = useMemo(
    () =>
      file.length ? (
        <FilePreview name={file[0].name} onCrossClick={() => setFile([])} />
      ) : (
        <div className="import-spreadsheet__content">
          <Uploader
            multiple={true}
            handleChange={(file: File[]) => setFile(file)}
            types={FILE_TYPES}
            classes="file-uploader"
            label="Click to browse or drag and drop your files"
            maxSize={MAX_SIZE}
          >
            <div className="file-uploader__content">
              <p>
                <span className="file-uploader__content_highlighted">{`Click `}</span>
                to browse
                <br /> or drag and drop your files
              </p>
            </div>
          </Uploader>
          <p className="file-uploader__template-link">
            Click to download the template{' '}
            <button onClick={getTableTemplate}>here</button>
          </p>
        </div>
      ),
    [file, getTableTemplate]
  );

  const onUploadClick = useCallback(() => {
    if (file.length === 1) {
      readFile(file[0]);
    }
  }, [file, readFile]);

  const onTryAgainClick = () => {
    setFile([]);
    setContentType(ContentType.UPLOAD);
  };

  const contentMap = useMemo(() => {
    return {
      [ContentType.UPLOAD]: (
        <Modal
          open={true}
          title="Import Spreadsheet"
          onBackClick={closeModal}
          confirmButtonText="Upload"
          backButtonText="Cancel"
          onConfirmClick={onUploadClick}
          content={fileUploader}
        />
      ),
      [ContentType.UPLOAD_SUCCESS]: (
        <Modal
          open={true}
          title="Import Spreadsheet"
          text="File successfully uploaded"
          onBackClick={closeModal}
          backButtonText="Close"
          hideConfirmButton
          icon={
            <Icon
              icon={IconType.Verify}
              className="import-spreadsheet__success"
            />
          }
        />
      ),
      [ContentType.UPLOAD_ERROR]: (
        <Modal
          open={true}
          title="Import Spreadsheet"
          text="Failed to upload the file successfully"
          confirmButtonText="Try again"
          onConfirmClick={onTryAgainClick}
          backButtonText="Close"
          onBackClick={closeModal}
          icon={
            <Icon
              icon={IconType.Warning}
              className="import-spreadsheet__error"
            />
          }
        />
      ),
      [ContentType.VALIDATION_ERROR]: (
        <Modal
          open={true}
          title="Import Spreadsheet"
          text="Required information is missing or invalid"
          confirmButtonText="Try again"
          onConfirmClick={onTryAgainClick}
          backButtonText="Close"
          onBackClick={closeModal}
          icon={
            <Icon
              icon={IconType.Warning}
              className="import-spreadsheet__error"
            />
          }
          content={
            <div className="import-spreadsheet__error-message">
              {displayValidationErrors()}
            </div>
          }
        />
      ),
    };
  }, [displayValidationErrors, fileUploader, onUploadClick]);

  return contentMap[contentType];
};

export default ImportSpreadsheet;
