import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getFirebaseToken, onMessageListener } from 'src/firebase';

import { UserRoles } from 'src/features/auth/models';
import { AUTH } from 'src/features/auth/slices/authSlice';
import { State } from 'src/features/store/store';

import { useLazySubscribeToNotificationsQuery } from '../../api/notificationsApi';

import {
  FirebaseNotificationPayload,
  setNotifications,
} from '../../firebase-config';
import { CONTAINER_ID_FIREBASE } from '../NotificationContainer/NotificationContainer';

import './FirebaseNotification.scss';

type NotificationContentProps = {
  title: string;
  body: string;
};

const NotificationContent = ({ title, body }: NotificationContentProps) => (
  <>
    <h3 className="notification-title">{title}</h3>
    <p className="notification-body">{body}</p>
  </>
);

const FirebaseNotification = () => {
  const [firebaseToken, setFirebaseToken] = useState<string>('');
  const [subscribe] = useLazySubscribeToNotificationsQuery();
  const userRoles = useSelector((s: State) => s[AUTH].roles);

  const authToken = localStorage.getItem('ncap-token');
  const localToken = localStorage.getItem('firebase-device-token');

  !localToken && getFirebaseToken(setFirebaseToken);

  useEffect(() => {
    if (!localToken && firebaseToken) {
      localStorage.setItem('firebase-device-token', firebaseToken);
    }
  }, [firebaseToken, localToken]);

  useEffect(() => {
    const token = firebaseToken !== '' ? firebaseToken : localToken;
    if (token && authToken) {
      subscribe({ deviceToken: token, topic: 'pya' });
      if (userRoles?.includes(UserRoles.GUEST)) {
        subscribe({ deviceToken: token, topic: 'guest' });
      }
    }
  }, [firebaseToken, subscribe, authToken, userRoles, localToken]);

  onMessageListener((payload) => {
    const notification = (payload as FirebaseNotificationPayload).notification;
    setNotifications(authToken, payload as FirebaseNotificationPayload);
    toast.info(
      <NotificationContent
        title={notification.title}
        body={notification.body}
      />,
      {
        containerId: CONTAINER_ID_FIREBASE,
      }
    );
  });

  return <></>;
};

export default FirebaseNotification;
