import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  GetAggregationResponse,
  GetAnalyticsResponse,
  GetTemplateBadgesResponse,
  GetTemplateNamesResponse,
  GetTotalsResponse,
  TotalDownloads,
} from '../models';

export const ANALYTICS_API = 'ANALYTICS_API';

const analyticsApi = createApi({
  reducerPath: ANALYTICS_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_TEMPLATES_URL,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem('ncap-token');
        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
      },
    }),
    {
      maxRetries: 0,
    }
  ),
  endpoints: (builder) => ({
    getTemplateNames: builder.query<GetTemplateNamesResponse, void>({
      query: () => {
        return {
          method: 'GET',
          url: 'analytics/gettemplatenames',
        };
      },
    }),
    getTemplateBadges: builder.query<GetTemplateBadgesResponse, void>({
      query: () => {
        return {
          method: 'GET',
          url: 'analytics/gettemplatebadges',
        };
      },
    }),
    getWalletDownloads: builder.query<GetAnalyticsResponse, void>({
      query: () => {
        return {
          method: 'GET',
          url: 'analytics/getwalletdownloads',
        };
      },
    }),
    getWalletDownloadsTotal: builder.query<
      GetTotalsResponse | { data: TotalDownloads },
      void
    >({
      query: () => {
        return {
          method: 'GET',
          url: 'analytics/getwalletdownloadstotal',
        };
      },
    }),
    getTestimonialsAnalytics: builder.query<GetAnalyticsResponse, void>({
      query: () => {
        return {
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/testimonial/analytics`,
        };
      },
    }),
    getAggregation: builder.query<GetAggregationResponse, void>({
      query: () => {
        return {
          method: 'GET',
          url: 'analytics/aggr',
        };
      },
    }),
    getAggregationByType: builder.query<GetAggregationResponse, string>({
      query: (request) => {
        return {
          method: 'GET',
          url: `analytics/aggrt/${request ?? ''}`,
        };
      },
    }),
    getAggregationByBatch: builder.query<GetAggregationResponse, string>({
      query: (request) => {
        return {
          method: 'GET',
          url: `analytics/aggb/${request ?? ''}`,
        };
      },
    }),
  }),
});

export const {
  useGetTemplateNamesQuery,
  useGetTemplateBadgesQuery,
  useGetAggregationQuery,
  useGetWalletDownloadsQuery,
  useGetWalletDownloadsTotalQuery,
  useGetTestimonialsAnalyticsQuery,
  useLazyGetAggregationByTypeQuery,
  useLazyGetAggregationByBatchQuery,
} = analyticsApi;

export const analyticsMiddleware = analyticsApi.middleware;

export default analyticsApi.reducer;
