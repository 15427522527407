import { Link } from 'react-router-dom';

import { Tab, Tabs as MaterialTabs } from '@mui/material';

type TabValue = {
  label: string;
  path?: string;
  isHidden?: boolean;
};

type TabsProps = {
  value: number;
  values: TabValue[];
  onChange?: (event: React.SyntheticEvent, value: number) => void;
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Tabs = ({ value, values, onChange }: TabsProps) => {
  return (
    <MaterialTabs
      value={value}
      onChange={onChange}
      textColor="primary"
      indicatorColor="primary"
      aria-label="basic tabs example"
    >
      {values
        .filter((tab) => !tab.isHidden)
        .map((tab, key) => (
          <Tab
            label={tab.label}
            key={key}
            {...a11yProps(value)}
            sx={{
              textTransform: 'capitalize',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '1em',
            }}
            {...(tab.path ? { component: Link, to: tab.path } : null)}
          />
        ))}
    </MaterialTabs>
  );
};

export default Tabs;
