import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import Input from 'src/shared/components/Input/Input';
import { OTHER } from 'src/shared/components/Select/Select';
import { ErrorResponse } from 'src/shared/models';
import { makeSelectorOptions } from 'src/shared/utils';

import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import {
  useCreateResponsiblePersonMutation,
  useUpdateResponsiblePersonByIdMutation,
} from 'src/features/responsiblePersons/api/responsiblePersonsApi';
import {
  CoCStatus,
  CreateResponsiblePersonRequest,
  ResponsiblePersonPosition,
} from 'src/features/responsiblePersons/models';
import {
  RESPONSIBLE_PERSONS,
  setResponsiblePerson,
} from 'src/features/responsiblePersons/slices/responsiblePersonsSlice';
import { State, store } from 'src/features/store/store';

import RPInfo from './components/RPInfo/RPInfo';

import './AddRP.scss';

export const enum AddRPTypes {
  CREATE,
  EDIT,
  PREFILL,
}

type AddRPProps = {
  type?: AddRPTypes;
};

interface ResponsiblePersonValues extends CreateResponsiblePersonRequest {
  positionOther: string;
}

export const POSITION_OPTIONS = makeSelectorOptions(ResponsiblePersonPosition);

export const COC_STATUS_OPTIONS = makeSelectorOptions(CoCStatus);

const INITIAL_VALUES = {
  uid: '',
  firstName: '',
  middleName: '',
  lastName: '',
  position: POSITION_OPTIONS[0].value,
  yachtName: '',
  cocNo: '',
  cocAuthority: '',
  cocStatus: COC_STATUS_OPTIONS[1].value,
  email: '',
};

const validationSchema = Yup.object({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  position: Yup.string().required('This field is required'),
  cocStatus: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('This field is required'),
});

const AddRP = ({ type = AddRPTypes.CREATE }: AddRPProps) => {
  const [createResponsiblePerson, creatingResult] =
    useCreateResponsiblePersonMutation();
  const [updateResponsiblePerson, updatingResult] =
    useUpdateResponsiblePersonByIdMutation();
  const person = useSelector(
    (s: State) => s[RESPONSIBLE_PERSONS].responsiblePerson
  );

  const initialFormValues = useMemo<CreateResponsiblePersonRequest>(
    () =>
      (type === AddRPTypes.EDIT || type === AddRPTypes.PREFILL) && person
        ? person
        : INITIAL_VALUES,
    [person, type]
  );

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setResponsiblePerson(null));
  };

  const transformData = (data: unknown) => {
    const rpData = data as ResponsiblePersonValues;
    return {
      ...(data as CreateResponsiblePersonRequest),
      position:
        rpData.position !== OTHER ? rpData.position : rpData.positionOther,
    };
  };

  const onSubmit = (data: CreateResponsiblePersonRequest | unknown) => {
    type === AddRPTypes.EDIT && person
      ? updateResponsiblePerson({
          ...transformData(data),
          id: person.uid,
        })
      : createResponsiblePerson({
          ...transformData(data),
          uid: uuidv4(),
        });
  };

  useEffect(() => {
    if (creatingResult.isError) {
      toast.error(
        `Creating error: ${
          (creatingResult.error as ErrorResponse).data.message
        }`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    } else if (creatingResult.isSuccess) {
      closeModal();
      toast.success('Created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [creatingResult]);

  useEffect(() => {
    if (updatingResult.isError) {
      toast.error(
        `Updating error: ${
          (updatingResult.error as ErrorResponse).data.message
        }`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    } else if (updatingResult.isSuccess) {
      closeModal();
      toast.success('Updated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [updatingResult]);

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <div className="modal__header">
        <DialogTitle maxWidth="1000px">
          {type === AddRPTypes.EDIT ? 'Change' : 'Add RP'}
        </DialogTitle>
      </div>

      <Formik
        initialValues={initialFormValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className="responsible-person-form">
          <DialogContent>
            <div className="responsible-person-form__name">
              <Input name="firstName" label="First name" />
              <Input name="middleName" label="Middle name" />
              <Input name="lastName" label="Last name" />
            </div>
            <RPInfo />
          </DialogContent>

          <DialogActions>
            <Button onClick={closeModal} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {type === AddRPTypes.EDIT
                ? 'Change'
                : 'Add a new responsible person'}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddRP;
