import { useFormikContext } from 'formik';

import Input from 'src/shared/components/Input/Input';

import { ResponsiblePersonPosition } from 'src/features/responsiblePersons/models';
import { Testimonial } from 'src/features/testimonials/models';

type ResponsiblePersonPositionProps = {
  disabledForm: boolean;
};

const ResponsiblePersonPositionInput = ({
  disabledForm,
}: ResponsiblePersonPositionProps) => {
  const { values } = useFormikContext<Testimonial>();
  const responsiblePersonPosition = values.responsiblePerson?.position ?? '';

  return (
    <>
      <Input
        name="responsiblePerson.position"
        label="Responsible person’s position"
        disabled={disabledForm}
      />
      {responsiblePersonPosition === ResponsiblePersonPosition.MASTER ||
      responsiblePersonPosition === ResponsiblePersonPosition.CAPTAIN ? (
        <></>
      ) : (
        <Input
          name="responsiblePerson.orgAndAddress"
          label="Organisation and address"
          disabled={disabledForm}
        />
      )}
    </>
  );
};

export default ResponsiblePersonPositionInput;
