import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { getBasicAuthKey } from 'src/shared/utils';

import {
  AccountData,
  ChangePasswordRequest,
  GetMyProfileResponse,
  ProfileInfo,
  ResetPasswordConfirmRequest,
  ResetPasswordRequest,
  SignInRequest,
  SignInResponse,
} from 'src/features/auth/models';

export const AUTH_API = 'AUTH_API';
// const path = `${window.location.protocol}//${window.location.host}`;

const authApi = createApi({
  reducerPath: AUTH_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_AUTH_URL,
    }),
    { maxRetries: 0 }
  ),
  tagTypes: ['ACCOUNT'],
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInResponse, SignInRequest>({
      query: (request) => ({
        method: 'GET',
        url: 'users/login',
        headers: {
          authorization: `Basic ${getBasicAuthKey(
            request.email,
            request.password
          )}`,
        },
      }),
      invalidatesTags: ['ACCOUNT'],
    }),
    getMyProfile: builder.query<GetMyProfileResponse, void>({
      query: () => ({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/user-profile/me`,
        headers: {
          authorization: `Bearer ${localStorage.getItem('ncap-token')}`,
        },
      }),
    }),
    getMyProfileByToken: builder.query<GetMyProfileResponse, string>({
      query: (token) => ({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/user-profile/me`,
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
    }),
    updateAccount: builder.mutation<void, AccountData>({
      query: (request) => ({
        method: 'POST',
        body: request,
        url: `${process.env.REACT_APP_TEMPLATES_URL}/account`,
        headers: {
          authorization: `Bearer ${localStorage.getItem('ncap-token')}`,
        },
      }),
      invalidatesTags: ['ACCOUNT'],
    }),
    updateProfile: builder.mutation<void, ProfileInfo>({
      query: (request) => ({
        method: 'PUT',
        body: request,
        url: `${process.env.REACT_APP_AUTH_URL}/users/update-profile`,
        headers: {
          authorization: `Bearer ${localStorage.getItem('ncap-token')}`,
        },
      }),
      invalidatesTags: ['ACCOUNT'],
    }),
    updateProfilePicture: builder.mutation<void, FormData>({
      query: (request) => ({
        method: 'PUT',
        body: request,
        formData: true,
        url: `${process.env.REACT_APP_API_URL}/user-profile/picture`,
        headers: {
          authorization: `Bearer ${localStorage.getItem('ncap-token')}`,
        },
      }),
      invalidatesTags: ['ACCOUNT'],
    }),
    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: (request) => ({
        method: 'POST',
        body: request,
        url: 'users/change-password',
        headers: {
          authorization: `Bearer ${localStorage.getItem('ncap-token')}`,
        },
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: (request) => ({
        method: 'POST',
        body: request,
        url: 'users/reset-password',
      }),
    }),
    resetPasswordConfirm: builder.mutation<void, ResetPasswordConfirmRequest>({
      query: (request) => ({
        method: 'POST',
        body: request,
        url: 'users/reset-confirm',
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useGetMyProfileQuery,
  useLazyGetMyProfileQuery,
  useLazyGetMyProfileByTokenQuery,
  useUpdateAccountMutation,
  useUpdateProfileMutation,
  useUpdateProfilePictureMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
} = authApi;

export const authMiddleware = authApi.middleware;

export default authApi.reducer;
