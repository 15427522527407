import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { paths } from 'src/app/routes';
import Modal from 'src/shared/components/Modal/Modal';
import { ErrorResponse } from 'src/shared/models';

import {
  useAddGUESTApplicationCommentMutation,
  useChangeGUESTApplicationStatusMutation,
} from 'src/features/cocRequests/api/cocRequestsApi';
import { GUESTApplicationStatus } from 'src/features/cocRequests/models';
import {
  COC_REQUEST,
  setRequestId,
} from 'src/features/cocRequests/slices/cocRequestsSlice';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State, store } from 'src/features/store/store';

import './ReturnCoCToSeafarer.scss';

const ReturnCoCToSeafarer = () => {
  const requestId = useSelector((s: State) => s[COC_REQUEST].requestId);

  const navigate = useNavigate();
  const [returnRequest, result] = useChangeGUESTApplicationStatusMutation();
  const [addComment, addCommentResult] =
    useAddGUESTApplicationCommentMutation();

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setRequestId(null));
  };

  const onConfirm = (comment: string | undefined) => {
    if (requestId) {
      returnRequest({
        uid: requestId,
        status: GUESTApplicationStatus.DOCUMENTS_REVISION,
      });
      addComment({
        uid: requestId,
        comment: comment ?? '',
      });
    }
  };

  useEffect(() => {
    if (result.isError) {
      closeModal();
      toast.error(`Error: ${(result.error as ErrorResponse).data.error}`, {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [result.isError, result.error]);

  useEffect(() => {
    if (result.isSuccess && addCommentResult.isSuccess) {
      closeModal();
      navigate(paths.cocRequests);
      toast.success('GUEST Rejected Successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [result.isSuccess, addCommentResult.isSuccess, navigate]);

  useEffect(() => {
    if (addCommentResult.isError) {
      closeModal();
      toast.error(
        `Error: ${(addCommentResult.error as ErrorResponse).data.message}`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
  }, [addCommentResult.error, addCommentResult.isError]);

  return (
    <Modal
      open={true}
      title="Are you sure you want to send the CoC application back to the seafarer?"
      text="Please indicate the reason why:"
      inputField
      confirmButtonText="Send"
      onBackClick={() => store.dispatch(changeModal(null))}
      onConfirmClick={onConfirm}
    />
  );
};

export default ReturnCoCToSeafarer;
