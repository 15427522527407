import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Breadcrumbs, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { paths, routes } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import Card from 'src/shared/components/Card/Card';
import Input from 'src/shared/components/Input/Input';
import PicturePicker from 'src/shared/components/PicturePicker/PicturePicker';
import Select from 'src/shared/components/Select/Select';
import TextArea from 'src/shared/components/TextArea/TextArea';
import { FORM_TYPES } from 'src/shared/enums';
import { countries } from 'src/shared/utils';

import { COMPANIES } from 'src/features/companies/slices/companiesSlice';
import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import {
  useCreateIssuerForCompanyMutation,
  useCreateIssuerMutation,
  useUpdateIssuerMutation,
} from 'src/features/issuers/api/issuersApi';
import {
  CreateIssuerRequest,
  UpdateIssuerRequest,
} from 'src/features/issuers/models';
import { ISSUERS } from 'src/features/issuers/slices/issuersSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State } from 'src/features/store/store';

import { SettingsContext } from '../Settings/SettingsContext';

import './IssuerForm.scss';

const INITIAL_VALUES = {
  id: '',
  name: '',
  email: '',
  url: '',
  image: '',
  country: '',
  countryId: '',
  phone: '',
  address: '',
  userId: '',
  type: 'ORGANIZATION',
  birthdate: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('This field is required'),
  image: Yup.string().required('This field is required'),
  country: Yup.string().required('This field is required'),
});

const COUNRTY_OPTIONS: { label: string; value: string }[] = countries;

const formTypes = {
  [FORM_TYPES.CREATE]: {
    title: 'New issuer',
    button: 'Create issuer',
  },
  [FORM_TYPES.EDIT]: {
    title: 'Edit issuer',
    button: 'Edit issuer',
  },
  [FORM_TYPES.VIEW]: {
    title: 'Issuer details',
    button: 'Close',
  },
};

type IssuerFormProps = {
  type: FORM_TYPES;
};

const IssuerForm = ({ type }: IssuerFormProps): JSX.Element => {
  const navigate = useNavigate();
  const settingsContext = useContext(SettingsContext);
  useCheckAuthorization();

  const issuer = useSelector((s: State) => s[ISSUERS].issuer);
  const currentCompany = useSelector((s: State) => s[COMPANIES].company);

  const initialFormValues = useMemo<CreateIssuerRequest | UpdateIssuerRequest>(
    () => (type !== FORM_TYPES.CREATE && issuer ? issuer : INITIAL_VALUES),
    [type, issuer]
  );
  const [createIssuer, creationResult] = useCreateIssuerMutation();
  const [createIssuerForCompany, creationForCompanyResult] =
    useCreateIssuerForCompanyMutation();
  const [updateIssuer, updatingResult] = useUpdateIssuerMutation();
  const onCreateIssuer = (values: CreateIssuerRequest) => {
    if (settingsContext?.prevPage === 'Company' && currentCompany) {
      creatingMutation.createIssuer({
        ...values,
        companyUid: currentCompany?.uid,
      });
    } else {
      createIssuer(values);
    }
  };

  const creatingMutation = useMemo(
    () =>
      settingsContext?.prevPage === 'Company'
        ? {
            createIssuer: createIssuerForCompany,
            creationResult: creationForCompanyResult,
          }
        : { createIssuer, creationResult },
    [
      createIssuer,
      createIssuerForCompany,
      creationForCompanyResult,
      creationResult,
      settingsContext?.prevPage,
    ]
  );

  const onEditIssuer = (values: UpdateIssuerRequest) => {
    issuer && updateIssuer({ ...values, id: issuer?.uid });
  };

  const backPath =
    settingsContext?.prevPage === 'Company'
      ? settingsContext?.getPath(currentCompany?.uid, routes.companies.issuers)
      : paths.settingsIssuers;

  useCheckError(
    creatingMutation.creationResult.isError,
    'Error creating the issuer'
  );
  useCheckError(updatingResult.isError, 'Error updating the issuer');

  useEffect(() => {
    if (creatingMutation.creationResult.isSuccess) {
      navigate(backPath);
      toast.success('Created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, creatingMutation.creationResult.isSuccess, backPath]);

  useEffect(() => {
    if (updatingResult.isSuccess) {
      navigate(paths.settingsIssuers);
      toast.success('Updated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, updatingResult.isSuccess]);

  return (
    <Page>
      <div className="issuer-form">
        <Breadcrumbs>
          <Link className="issuer-form__bread-crumbs" to={backPath}>
            {settingsContext?.prevPage ?? 'Issuers'}
          </Link>
          <h1>{formTypes[type].title}</h1>
        </Breadcrumbs>
        <Card className="issuer-form__card">
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            onSubmit={type === FORM_TYPES.EDIT ? onEditIssuer : onCreateIssuer}
            validationSchema={validationSchema}
          >
            <Form className="issuer-form__form">
              <PicturePicker
                title="Logo"
                name="image"
                className="create-template-form__picture"
                disabled={type === FORM_TYPES.VIEW}
              />
              <div>
                <Input
                  name="name"
                  placeholder="Organisation name"
                  label="Name"
                  className="issuer-form__input"
                  disabled={type === FORM_TYPES.VIEW}
                  required
                />
                <div className="issuer-form__inputs-group">
                  <Input
                    name="email"
                    placeholder="Email"
                    label="Email"
                    disabled={type === FORM_TYPES.VIEW}
                    required
                  />
                  <Input
                    name="url"
                    placeholder="Webside address"
                    label="Website URL"
                    disabled={type === FORM_TYPES.VIEW}
                  />
                  <Select
                    name="country"
                    label="Country"
                    options={COUNRTY_OPTIONS}
                    placeholder="Choose country"
                    disabled={type === FORM_TYPES.VIEW}
                    required
                  />
                  <Input
                    name="countryId"
                    placeholder="Ex.: 1234567"
                    label="Tax ID"
                    disabled={type === FORM_TYPES.VIEW}
                  />
                </div>
              </div>
              <div>
                <div className="issuer-form__header-container">
                  <h2>Additional information</h2>
                  <p>Optional</p>
                </div>

                <Input
                  name="phone"
                  placeholder="Ex.: 12345678"
                  label="Phone Number"
                  className="issuer-form__input"
                  disabled={type === FORM_TYPES.VIEW}
                />
                <TextArea
                  name="address"
                  label="Address"
                  placeholder="Recipient address"
                  disabled={type === FORM_TYPES.VIEW}
                />
              </div>
              <div className="issuer-form__buttons">
                <Button
                  color="secondary"
                  className="settings__form-button_secondary"
                  component={Link}
                  to={backPath}
                >
                  {type === FORM_TYPES.VIEW
                    ? formTypes[type].button
                    : 'Discard'}
                </Button>
                {type !== FORM_TYPES.VIEW && (
                  <Button variant="contained" type="submit">
                    {formTypes[type].button}
                  </Button>
                )}
              </div>
            </Form>
          </Formik>
        </Card>
      </div>
    </Page>
  );
};

export default IssuerForm;
