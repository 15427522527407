import { configureStore } from '@reduxjs/toolkit';

import { analyticsMiddleware } from 'src/features/analytics/api/analyticsApi';
import { authMiddleware } from 'src/features/auth/api/authApi';
import { campaignsMiddleware } from 'src/features/campaigns/api/campaignsApi';
import { cocRequestsMiddleware } from 'src/features/cocRequests/api/cocRequestsApi';
import { companiesMiddleware } from 'src/features/companies/api/companiesApi';
import { credentialsMiddleware } from 'src/features/credentials/api/credentialsApi';
import { documentsMiddleware } from 'src/features/documents/api/documentsApi';
import { issuersMiddleware } from 'src/features/issuers/api/issuersApi';
import { mobileMiddleware } from 'src/features/mobile/api/mobileApi';
import { notificationsMiddleware } from 'src/features/notifications/api/notificationsApi';
import { paymentMiddleware } from 'src/features/payment/api/paymentApi';
import { responsiblePersonsMiddleware } from 'src/features/responsiblePersons/api/responsiblePersonsApi';
import { templatesMiddleware } from 'src/features/templates/api/templatesApi';
import { testimonialsMiddleware } from 'src/features/testimonials/api/testimonialsApi';

import rootReducer from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authMiddleware,
      paymentMiddleware,
      documentsMiddleware,
      templatesMiddleware,
      analyticsMiddleware,
      companiesMiddleware,
      issuersMiddleware,
      credentialsMiddleware,
      campaignsMiddleware,
      cocRequestsMiddleware,
      testimonialsMiddleware,
      notificationsMiddleware,
      responsiblePersonsMiddleware,
      mobileMiddleware
    ),
});

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
