import { useEffect } from 'react';

import { useFormikContext } from 'formik';

import Input from 'src/shared/components/Input/Input';
import { FormField } from 'src/shared/models';

import './SeaServiceDays.scss';

const SeaServiceDays = () => {
  const { setFieldValue, values } = useFormikContext<FormField>();

  const getYearsValue = (months: number, days: number) => {
    if (Number.isNaN(months) || Number.isNaN(days)) {
      return '';
    }
    return (days / 365 + months / 12).toFixed(2);
  };

  useEffect(() => {
    setFieldValue(
      'yearsYachtService',
      getYearsValue(
        parseInt(values['monthsYachtService']),
        parseInt(values['daysYachtService'])
      )
    );
    setFieldValue(
      'yearsGuest',
      getYearsValue(
        parseInt(values['monthsGuest']),
        parseInt(values['daysGuest'])
      )
    );
    setFieldValue(
      'yearsMaritimeService',
      getYearsValue(
        parseInt(values['monthsMaritimeService']),
        parseInt(values['daysMaritimeService'])
      )
    );
  }, [setFieldValue, values]);

  const formulaComponents = (prefix: string) => (
    <>
      <Input name={`months${prefix}`} label="Months" type="number" disabled />
      <span>+</span>
      <Input name={`days${prefix}`} label="Days" disabled />
      <span>=</span>
      <Input name={`years${prefix}`} label="Years" disabled />
    </>
  );

  return (
    <div className="sea-service-days__container">
      <ul className="sea-service-days__list">
        <li>
          <p className="sea-service-days__item-header">Yacht Service</p>
          {formulaComponents('YachtService')}
        </li>
        <li>
          <p className="sea-service-days__item-header">Guest Days</p>
          {formulaComponents('Guest')}
        </li>
        <li>
          <p className="sea-service-days__item-header">Maritime Service</p>
          {formulaComponents('MaritimeService')}
        </li>
      </ul>
      <p className="sea-service-days__note">{'(Note: 1 month = 30 days)'}</p>
    </div>
  );
};

export default SeaServiceDays;
