import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useGetMyProfileQuery } from 'src/features/auth/api/authApi';
import {
  setUserProfile,
  setUserRoles,
} from 'src/features/auth/slices/authSlice';
import { decodeProfileInfo } from 'src/features/auth/utils';
import { ModalBase } from 'src/features/modal/ModalBase';
import { store } from 'src/features/store/store';

import Sidebar from './components/Sidebar/Sidebar';

import './BaseLayout.scss';

const BaseLayout = () => {
  const authToken = localStorage.getItem('ncap-token');
  const profileInfo = decodeProfileInfo(authToken);

  const { data: profile } = useGetMyProfileQuery();

  useEffect(() => {
    if (profile) {
      store.dispatch(setUserProfile(profile?.data ?? null));
      store.dispatch(setUserRoles(profile?.data.roles ?? null));
    }

    if (authToken && profileInfo.userUid) {
      store.dispatch(
        setUserProfile({
          userUid: profileInfo.userUid,
          firstName:
            profile?.data.firstName !== ''
              ? profile?.data.firstName
              : profileInfo.firstName,
          lastName:
            profile?.data.lastName !== ''
              ? profile?.data.lastName
              : profileInfo.lastName,
        })
      );
    }
  }, [authToken, profile, profileInfo]);

  return (
    <div className="base-layout">
      <Sidebar />
      <main>
        <Outlet />
      </main>
      <ModalBase />
    </div>
  );
};

export default BaseLayout;
