import { DatePicker as Datepicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getIn, useField, useFormikContext } from 'formik';

import TextError from 'src/shared/components/TextError/TextError';
import { DATE_FORMAT } from 'src/shared/utils';

import { ReactComponent as CalendarIcon } from './images/calendar-icon.svg';

import './DatePicker.scss';

dayjs.extend(utc);

type DatePickerProp = {
  label: string;
  name?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (value: dayjs.Dayjs | null) => void;
  minDate?: string;
  maxDate?: string;
  required?: boolean;
  format?: string;
};

const DatePicker = ({
  label,
  name,
  disabled = false,
  value,
  onChange,
  minDate,
  maxDate,
  required,
  format,
}: DatePickerProp) => {
  const { setFieldValue, errors, touched, setFieldTouched, validateForm } =
    useFormikContext();
  const [field] = useField(name ?? 'f');

  const getValue = () => {
    if (field.value) {
      return dayjs(field.value, format ?? DATE_FORMAT);
    } else {
      return value ? dayjs(value) : null;
    }
  };

  const onChangeValue = (value: dayjs.Dayjs | null) => {
    if (name) {
      setFieldValue(name, value?.utc(true));
      setFieldTouched(name);
      validateForm();
    } else {
      onChange && onChange(value);
    }
  };

  return (
    <div className="date-picker">
      {label ? (
        <label className="input__label" htmlFor={name}>
          {label}
          {required ? <span className="input__label_required">*</span> : null}
        </label>
      ) : null}
      <Datepicker<Dayjs>
        defaultValue={null}
        value={getValue()}
        onChange={onChangeValue}
        className="date-picker__input"
        disabled={disabled}
        format="DD-MM-YYYY"
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        minDate={minDate ? dayjs(minDate) : undefined}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
      />
      {name && !disabled && getIn(errors, name) && getIn(touched, name) ? (
        <TextError>{getIn(errors, name)}</TextError>
      ) : null}
    </div>
  );
};

export default DatePicker;
