import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { encode as base64_encode } from 'base-64';
import dayjs from 'dayjs';
import { Form, Formik, FormikValues } from 'formik';
import pLimit from 'p-limit';

import { paths } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import Card from 'src/shared/components/Card/Card';
import Select from 'src/shared/components/Select/Select';
import Spinner from 'src/shared/components/Spinner/Spinner';
import {
  DATE_FORMAT,
  DATE_WITHOUT_TIMEZONE,
  REQUEST_DATE_FORMAT,
} from 'src/shared/utils';

import { useCreateCredentialMutation } from 'src/features/credentials/api/credentialsApi';
import {
  CreateCredentialRequest,
  CreateCredentialWithDocumentRequest,
} from 'src/features/credentials/models';
import {
  clearCredentialDetails,
  CREDENTIALS,
  deleteCredentialDetailByIndex,
  setCredentialsCreatingResult,
} from 'src/features/credentials/slices/credentialsSlice';
import { useLazySendDocumentQuery } from 'src/features/documents/api/documentsApi';
import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import { useGetIssuersQuery } from 'src/features/issuers/api/issuersApi';
import { Issuer } from 'src/features/issuers/models';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State, store } from 'src/features/store/store';
import { useGetTemplatesQuery } from 'src/features/templates/api/templatesApi';
import { LabelTypes, Template } from 'src/features/templates/models';
import { TEMPLATES } from 'src/features/templates/slices/templatesSlice';

import AddRecipientDetails from './components/AddRecipientDetails/AddRecipientDetails';
import ChooseTemplate from './components/ChooseTemplate/ChooseTemplate';

import './NewCredentialCreating.scss';

const REQUESTS_LIMIT = 10;

type AutofillValues = {
  issuer: Issuer | null;
  template: Template | null;
};

const INITIAL_VALUES = {
  templateUid: '',
  issuerUid: '',
  data: {
    credential: {
      comment: '',
      expires: '',
      issuedOn: '',
      signed: false,
    },
    recipient: {
      address: '',
      birthdate: '',
      countryId: '',
      email: '',
      firstName: '',
      lastName: '',
      secondaryIdentifier: '',
      phone: '',
    },
  },
};

const NewCredentialCreating = (): JSX.Element => {
  const navigate = useNavigate();
  useCheckAuthorization();
  const limit = pLimit(REQUESTS_LIMIT);
  const [creatingIsLoading, setCreatingIsLoading] = useState<boolean>(false);
  const credentialDetails = useSelector(
    (s: State) => s[CREDENTIALS].credentialRecipientsDetails
  );
  const templateValues = useSelector((s: State) => s[TEMPLATES].template);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);

  const {
    data: templates,
    isError: templatesError,
    isLoading: templatesLoading,
  } = useGetTemplatesQuery();
  useCheckError(templatesError, 'Error loading Templates');

  const {
    data: issuers,
    isError: issuersError,
    isLoading: issuersLoading,
  } = useGetIssuersQuery();
  useCheckError(issuersError, 'Error loading Issuers');

  const [createCredential, createResult] = useCreateCredentialMutation();
  useCheckError(createResult.isError, 'Error creating new Credential');

  const [sendDocument, sendDocumentResult] = useLazySendDocumentQuery();
  useCheckError(sendDocumentResult.isError, 'Error creating new Credential');

  const [credentialType, setCredentialType] = useState<string>('');
  const types = useMemo(
    () =>
      templates
        ? templates?.data.reduce(
            (accumulator: string[], template: Template) => {
              if (template.type && !accumulator.includes(template.type)) {
                accumulator.push(template.type);
              }
              return accumulator;
            },
            []
          )
        : [],
    [templates]
  );
  const templatesList = useMemo(() => {
    if (types.length > 1) {
      return (
        templates?.data.filter(
          (template) => template.type === credentialType
        ) ?? []
      );
    } else {
      return templates?.data ?? [];
    }
  }, [credentialType, templates?.data, types.length]);

  const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentialType((event.target as HTMLInputElement).value);
  };

  const displayCredentialTypes = useMemo(() => {
    if (types.length > 0) {
      setCredentialType(types[0]);
      if (types.length === 1) {
        return null;
      }
      const control = (
        <FormControl>
          <FormLabel>Credential Type</FormLabel>
          <RadioGroup
            defaultValue={types[0]}
            name="credentialType"
            row
            onChange={onTypeChange}
          >
            {types?.map((type, key) => (
              <FormControlLabel
                key={key}
                value={type}
                control={<Radio />}
                label={type}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
      return control;
    }
    return null;
  }, [types]);

  const autofillValues: AutofillValues = useMemo(() => {
    return {
      issuer: issuers?.data.length === 1 ? issuers.data[0] : null,
      template: templatesList.length === 1 ? templatesList[0] : null,
    };
  }, [issuers?.data, templatesList]);

  const template = autofillValues.template ?? templateValues;

  const displayIssuers = useMemo(() => {
    if (!issuers || issuers?.data.length === 0) {
      return (
        <p className="choose-signatory__no-data-message">
          There are no issuers created for this account. Please contact the
          system administrator or support team.
        </p>
      );
    }
    return autofillValues.issuer ? null : (
      <Select
        name="issuerUid"
        label="Issuer"
        options={
          issuers?.data.map((issuer) => {
            return {
              value: issuer.uid,
              label: issuer.name,
            };
          }) ?? []
        }
        placeholder="Select Issuer"
        required
      />
    );
  }, [autofillValues.issuer, issuers]);

  const onSubmit = (values: FormikValues) => {
    const credentialValues = values as CreateCredentialRequest;

    if (values.file && credentialDetails.length === 1) {
      const requestObject: CreateCredentialWithDocumentRequest = {
        credential: {
          issuedOn: dayjs(credentialDetails[0].issuedOn, DATE_FORMAT).format(
            REQUEST_DATE_FORMAT
          ),
          comment: credentialDetails[0].comment,
          expires: credentialDetails[0].expires
            ? dayjs(credentialDetails[0].expires, DATE_FORMAT).format(
                REQUEST_DATE_FORMAT
              )
            : undefined,
          templateId: autofillValues.template
            ? autofillValues.template.uid
            : credentialValues.templateUid,
        },
        recipient: {
          ...credentialDetails[0],
          email: credentialDetails[0].email ?? '',
          birthdate: credentialDetails[0].birthdate
            ? dayjs(credentialDetails[0].birthdate, DATE_FORMAT).format(
                REQUEST_DATE_FORMAT
              )
            : undefined,
        },
      };
      if (values.sendToEmail) {
        requestObject.sendToEmail = true;
      }
      const payload = base64_encode(JSON.stringify(requestObject));

      const formData = new FormData();
      formData.append('payload', payload);
      formData.append(
        'issuerUid',
        autofillValues.issuer
          ? autofillValues.issuer.uid
          : credentialValues.issuerUid
      );
      formData.append('templateUid', template?.uid ?? '');
      formData.append('file', values.file);

      sendDocument(formData);
    } else {
      setCreatingIsLoading(true);
      const fixedTextValues = template
        ? Object.fromEntries(
            Object.keys(template.renderParams).map((name) => {
              if (
                (template.renderParams[name].fieldType as LabelTypes) ===
                LabelTypes.FIXED_TEXT
              ) {
                if (template?.renderParams[name]) {
                  return [name, template?.renderParams[name].fieldLabel];
                }
                return [];
              }
              return [];
            })
          )
        : [];
      delete fixedTextValues.undefined;
      const requests = credentialDetails.map((elem) => {
        const requestObject: CreateCredentialRequest = {
          templateUid: autofillValues.template
            ? autofillValues.template.uid
            : credentialValues.templateUid,
          issuerUid: autofillValues.issuer
            ? autofillValues.issuer.uid
            : credentialValues.issuerUid,
          data: {
            credential: {
              issuedOn: dayjs(elem.issuedOn, DATE_FORMAT).format(
                REQUEST_DATE_FORMAT
              ),
              expires: elem.expires
                ? dayjs(elem.expires, DATE_FORMAT).format(REQUEST_DATE_FORMAT)
                : undefined,
              comment: elem.comment,
              signed: true,
              externalIdentifier: elem.externalIdentifier,
            },
            recipient: {
              ...elem,
              email: elem.email ?? '',
              birthdate: elem.birthdate
                ? dayjs(elem.birthdate, DATE_FORMAT).format(REQUEST_DATE_FORMAT)
                : undefined,
              ...fixedTextValues,
            },
          },
        };
        if (
          template?.renderParams &&
          Object.keys(template?.renderParams).length > 0
        ) {
          const renderParamsObject = Object.fromEntries(
            Object.keys(template?.renderParams ?? []).map((key) => {
              if (key in elem) {
                if (
                  template?.renderParams[key].fieldType === LabelTypes.DATE ||
                  key === 'birthdate' ||
                  key === 'issuedOn' ||
                  key === 'expires'
                ) {
                  return [
                    key,
                    elem[key]
                      ? dayjs(elem[key]?.toString(), DATE_FORMAT).format(
                          DATE_WITHOUT_TIMEZONE
                        )
                      : '',
                  ];
                }
                return [key, elem[key]];
              }
              return [];
            })
          );
          requestObject.renderParams = {
            ...renderParamsObject,
            ...fixedTextValues,
          };
        }
        if (values.sendToEmail) {
          requestObject.sendToEmail = true;
        }
        delete requestObject.data.recipient.index;
        delete requestObject.data.recipient.id;
        return limit(() => createCredential(requestObject));
      });
      Promise.allSettled(requests)
        .then((result) => {
          const successIndexes: number[] = [];
          const failed = result.reduce(
            (accumulator, currentValue, currentIndex) => {
              if (
                currentValue.status === 'rejected' ||
                'error' in currentValue.value
              ) {
                return (accumulator += 1);
              }
              successIndexes.push(currentIndex);
              return accumulator;
            },
            0
          );
          setCreatingIsLoading(false);
          store.dispatch(
            setCredentialsCreatingResult({
              total: result.length,
              failed: failed,
            })
          );
          store.dispatch(changeModal(MODALS.CREDENTIALS_CREATION));
          if (failed > 0) {
            successIndexes
              .reverse()
              .forEach((index) =>
                store.dispatch(deleteCredentialDetailByIndex(index))
              );
          }
        })
        .catch(() => {
          setCreatingIsLoading(false);
          store.dispatch(
            setCredentialsCreatingResult({
              total: credentialDetails.length,
              failed: credentialDetails.length,
            })
          );
          store.dispatch(changeModal(MODALS.CREDENTIALS_CREATION));
        });
    }
  };

  useEffect(() => {
    if (sendDocumentResult.isSuccess) {
      navigate(paths.credentials);
      toast.success('Credential created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
      store.dispatch(clearCredentialDetails());
    }
  }, [createResult, navigate, sendDocumentResult.isSuccess]);

  useEffect(() => {
    return () => {
      store.dispatch(clearCredentialDetails());
    };
  }, []);

  return (
    <Page>
      <div className="credentials">
        <Breadcrumbs>
          <Link
            className="create-template__bread-crumbs"
            to={paths.credentials}
          >
            Credentials
          </Link>
          <h1>New credential</h1>
        </Breadcrumbs>
        <Formik initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
          <Form className="create-credential">
            <Card className="create-credential__card">
              {templatesLoading || issuersLoading ? (
                <Spinner />
              ) : (
                <>
                  {displayIssuers}
                  {displayCredentialTypes}
                  <ChooseTemplate templates={templatesList} />
                </>
              )}
            </Card>
            <Card className="create-credential__card">
              <AddRecipientDetails
                templateWithDesign={template?.designTemplateUid !== ''}
                onFileChange={setIsFileUploaded}
              />
            </Card>
            <div className="create-credential__buttons">
              <Button
                color="secondary"
                className="settings__form-button_secondary"
                onClick={() => navigate(paths.credentials)}
                disabled={creatingIsLoading || sendDocumentResult.isLoading}
              >
                Discard
              </Button>
              {creatingIsLoading || sendDocumentResult.isLoading ? (
                <Spinner className="credential-details__spinner" />
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    (template?.designTemplateUid &&
                      credentialDetails.length === 0) ||
                    (template?.designTemplateUid === '' &&
                      (!isFileUploaded || credentialDetails.length !== 1)) ||
                    !issuers ||
                    issuers?.data.length === 0
                  }
                >
                  Generate
                </Button>
              )}
            </div>
          </Form>
        </Formik>
      </div>
    </Page>
  );
};

export default NewCredentialCreating;
