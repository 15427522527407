import { useEffect, useMemo, useState } from 'react';

import { Divider, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  Bar,
  BarChart as Chart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  useLazyGetAggregationByBatchQuery,
  useLazyGetAggregationByTypeQuery,
} from 'src/features/analytics/api/analyticsApi';

import ChartFilterButtons, {
  BUTTONS_TYPE,
} from '../ChartFilterButtons/ChartFilterButtons';

export interface TypeObject {
  id: string;
  name: string;
}

interface TypeChartProps {
  types: TypeObject[] | [];
  batch?: boolean;
}

enum TABS {
  TOTAL = 'Total',
  TODAY = 'Today',
  WEEK = 'Week',
  MONTH = 'Month',
}

const TypeChart = ({ types, batch }: TypeChartProps) => {
  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.TOTAL);

  const [getTypeAggregation, typeAggregation] =
    useLazyGetAggregationByTypeQuery();
  const [getBatchAggregation, batchAggregation] =
    useLazyGetAggregationByBatchQuery();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    batch ? getBatchAggregation(value) : getTypeAggregation(value);
  };

  const aggregation = useMemo(
    () => (batch ? batchAggregation : typeAggregation),
    [batch, batchAggregation, typeAggregation]
  );

  const data = useMemo(() => {
    return {
      [TABS.TOTAL]: [
        {
          name: 'Total',
          value: aggregation.data?.data.TOTAL,
        },
      ],
      [TABS.TODAY]: aggregation.data?.data.DAY ?? [],
      [TABS.WEEK]: aggregation.data?.data.WEEK ?? [],
      [TABS.MONTH]: aggregation.data?.data.MONTH ?? [],
    };
  }, [
    aggregation.data?.data.TOTAL,
    aggregation.data?.data.DAY,
    aggregation.data?.data.WEEK,
    aggregation.data?.data.MONTH,
  ]);

  useEffect(() => {
    if (batch) {
      getBatchAggregation(types.length ? types[0].id : '');
    } else {
      getTypeAggregation(types.length ? types[0].id : '');
    }
  }, [batch, getBatchAggregation, getTypeAggregation, types]);

  const FILTERS = [
    {
      name: TABS.TOTAL,
      onClick: () => setSelectedTab(TABS.TOTAL),
      selected: selectedTab === TABS.TOTAL,
    },
    {
      name: TABS.TODAY,
      onClick: () => setSelectedTab(TABS.TODAY),
      selected: selectedTab === TABS.TODAY,
    },
    {
      name: TABS.WEEK,
      onClick: () => setSelectedTab(TABS.WEEK),
      selected: selectedTab === TABS.WEEK,
    },
    {
      name: TABS.MONTH,
      onClick: () => setSelectedTab(TABS.MONTH),
      selected: selectedTab === TABS.MONTH,
    },
  ];

  return (
    <>
      <Select
        defaultValue={types.length ? types[0].id : ''}
        onChange={handleChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {types.map((type) => (
          <MenuItem value={type.id} key={type.id}>
            {type.name}
          </MenuItem>
        ))}
      </Select>
      <ChartFilterButtons filters={FILTERS} type={BUTTONS_TYPE.TEXT} />
      <Divider />
      <ResponsiveContainer width="100%" height="90%">
        <Chart
          data={data[selectedTab]}
          margin={{
            top: 20,
            right: 50,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" style={{ fontSize: '14px' }} />
          <YAxis allowDecimals={false} style={{ fontSize: '14px' }} />
          <Tooltip />
          <Bar dataKey="value" fill="var(--ncap-600)" radius={[4, 4, 0, 0]} />
        </Chart>
      </ResponsiveContainer>
    </>
  );
};

export default TypeChart;
