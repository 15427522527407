import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { MRT_ColumnDef } from 'material-react-table';

import { paths } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';
import Table from 'src/shared/components/Table/Table';
import { DATE_WITHOUT_TIMEZONE } from 'src/shared/utils';

import { useGetCompaniesQuery } from 'src/features/companies/api/companiesApi';
import { Company } from 'src/features/companies/models';
import { setCompany } from 'src/features/companies/slices/companiesSlice';
import { useCheckError } from 'src/features/hooks';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { store } from 'src/features/store/store';

import CompanyInfo from '../CompanyInfo/CompanyInfo';

import './AdminsSettings.scss';

const AdminsSettings = () => {
  const { id } = useParams();

  const { data: companies, isError } = useGetCompaniesQuery();
  useCheckError(isError, 'Error loading Companies');

  const companyColumns: MRT_ColumnDef[] = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'companyName',
      },
      {
        header: 'Email',
        accessorKey: 'email',
        size: 100,
      },
      {
        header: 'VAT',
        accessorKey: 'vatNumber',
        size: 100,
      },
      {
        header: 'City (Country Code)',
        accessorKey: 'city',
        size: 100,
      },
      {
        header: 'Creation Date',
        accessorKey: 'createdAt',
        Cell: ({ cell }) =>
          dayjs(cell.getValue<string>()).format(DATE_WITHOUT_TIMEZONE),
      },
      {
        header: '',
        id: 'form',
        size: 50,
        Cell: ({ row }) => {
          const uid = (row.original as Company).uid;
          return (
            <Link
              to={paths.companyById(uid)}
              onClick={() =>
                store.dispatch(setCompany(row.original as Company))
              }
            >
              <Icon icon={IconType.ChevronSquare} />
            </Link>
          );
        },
      },
    ],
    []
  );

  const companiesList = (
    <>
      <div className="settings-admins__header">
        <h2>Companies</h2>
        <Button
          variant="contained"
          startIcon={<Icon icon={IconType.Plus} />}
          onClick={() => store.dispatch(changeModal(MODALS.ADD_COMPANY))}
        >
          Create company
        </Button>
      </div>
      <Table<Company>
        columns={companyColumns}
        data={companies ? companies.data.companies : []}
        enableSorting
      />
    </>
  );

  return (
    <div className="settings__admins">
      {id ? <CompanyInfo /> : companiesList}
    </div>
  );
};

export default AdminsSettings;
