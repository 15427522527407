import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';

import {
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { BLANK_PDF, Template } from '@pdfme/common';
import { image, text } from '@pdfme/schemas';
import { Designer } from '@pdfme/ui';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { routes } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import Card from 'src/shared/components/Card/Card';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';
import Input from 'src/shared/components/Input/Input';
import PicturePicker from 'src/shared/components/PicturePicker/PicturePicker';
import Select from 'src/shared/components/Select/Select';
import Spinner from 'src/shared/components/Spinner/Spinner';
import DEFAULT_TEMPLATE from 'src/shared/components/TemplateDesigner/default-template.json';
import boolean from 'src/shared/components/TemplateDesigner/plugins/boolean';
import date from 'src/shared/components/TemplateDesigner/plugins/date';
import fixedText from 'src/shared/components/TemplateDesigner/plugins/fixedText';
import number from 'src/shared/components/TemplateDesigner/plugins/number';
import shortText from 'src/shared/components/TemplateDesigner/plugins/shortText';
import {
  cloneDeep,
  downloadJsonFile,
  generatePDF,
  handleLoadTemplate,
  readFile,
} from 'src/shared/components/TemplateDesigner/utils';
import { ErrorResponse } from 'src/shared/models';

import { COMPANIES } from 'src/features/companies/slices/companiesSlice';
import { SupportTypeEnum } from 'src/features/credentials/models';
import { useCreateDocumentTemplateMutation } from 'src/features/documents/api/documentsApi';
import { useCheckAuthorization } from 'src/features/hooks';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State } from 'src/features/store/store';
import {
  useCreateTemplateForCompanyMutation,
  useCreateTemplateMutation,
} from 'src/features/templates/api/templatesApi';
import {
  CreateCredentialTemplateRequest,
  LabelTypes,
  TEMPLATE_FIELD_TYPES,
} from 'src/features/templates/models';

import { useLoadFonts } from './hooks';
import { SettingsContext } from '../Settings/SettingsContext';

import './CreateTemplate.scss';

const modelMap = {
  model1: () => import('./templates/model1.json'),
  model2: () => import('./templates/model2.json'),
  model3: () => import('./templates/model3.json'),
};

interface FormValues {
  templateName: string;
  image: string;
}

interface SelectOptionProps {
  value: string;
  label: string;
}

const initialValues = {
  templateName: '',
  image: '',
};

const createTemplateValidation = Yup.object().shape({
  templateName: Yup.string().required('This field is required'),
});

const BASE_PDF_VALUES = [
  {
    label: 'Certificate Model 1',
    value: 'model1',
  },
  {
    label: 'Certificate Model 2',
    value: 'model2',
  },
  {
    label: 'Certificate Model 3',
    value: 'model3',
  },
  {
    label: 'Custom PDF',
    value: 'other',
  },
];

const TEMPLATE_TYPES = ['Certificate', 'Membership', 'D-SRB'];

const FILE_SIZE = 1048576; // 1MB

const CreateTemplate = (): JSX.Element => {
  const designerRef = useRef<HTMLDivElement | null>(null);
  const designer = useRef<Designer | null>(null);
  const [basePdf, setBasePdf] = useState<SelectOptionProps | null>(
    BASE_PDF_VALUES[0]
  );
  const [pdfTemplate, setPdfTemplate] = useState<Template | null>();
  const [credentialType, setCredentialType] = useState<string>(
    TEMPLATE_TYPES[0].toUpperCase()
  );

  const currentCompany = useSelector((s: State) => s[COMPANIES].company);
  const settingsContext = useContext(SettingsContext);
  const navigate = useNavigate();

  const isCompanyTemplate = settingsContext?.prevPage === 'Company';
  const backPath = isCompanyTemplate
    ? settingsContext?.getPath(currentCompany?.uid, routes.companies.templates)
    : '';

  const template: Template = useMemo(() => {
    if (pdfTemplate && basePdf && basePdf.value !== 'other') {
      return pdfTemplate;
    }
    if (designer.current) {
      return {
        basePdf: designer.current.getTemplate().basePdf,
        schemas: DEFAULT_TEMPLATE.schemas,
        sampledata: DEFAULT_TEMPLATE.sampledata,
      };
    }
    return DEFAULT_TEMPLATE;
  }, [basePdf, pdfTemplate]);
  const [createTemplate, createTemplateResult] =
    useCreateDocumentTemplateMutation();
  const [createCredentialTemplate, createCredentialTemplateResult] =
    useCreateTemplateMutation();
  const [createTemplateForCompany, createTemplateForCompanyResult] =
    useCreateTemplateForCompanyMutation();

  useCheckAuthorization();

  const getSupportValues = () => {
    return Object.keys(SupportTypeEnum).map((key) => {
      return {
        label: key,
        value: key,
      };
    });
  };

  const initialTemplateValues = isCompanyTemplate
    ? { ...initialValues, support: '' }
    : initialValues;

  const validationSchema = isCompanyTemplate
    ? createTemplateValidation.concat(
        Yup.object().shape({
          support: Yup.string().required('This field is required'),
        })
      )
    : createTemplateValidation;

  const fonts = useLoadFonts();

  useEffect(() => {
    if (designerRef.current) {
      designer.current = new Designer({
        domContainer: designerRef.current,
        template,
        plugins: {
          'Short text': shortText,
          'Long text': text,
          'Fixed text': fixedText,
          Numeric: number,
          Date: date,
          'True/False': boolean,
          Image: image,
        },
        options: {
          font: fonts,
        },
      });
    }
  });

  useEffect(() => {
    modelMap.model1().then((res) => {
      setPdfTemplate(res);
    });
  }, []);

  const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentialType((event.target as HTMLInputElement).value);
  };

  const onChangeBasePDF = (e: SelectOptionProps | null) => {
    if (e) {
      switch (e.value) {
        case 'model1':
          modelMap.model1().then((res) => {
            setBasePdf(e);
            setPdfTemplate(res);
          });
          break;
        case 'model2':
          modelMap.model2().then((res) => {
            setBasePdf(e);
            setPdfTemplate(res);
          });
          break;
        case 'model3':
          modelMap.model3().then((res) => {
            setBasePdf(e);
            setPdfTemplate(res);
          });
          break;
        case 'other':
          setBasePdf(e);
          break;
        default:
          break;
      }
    }
  };

  const onUploadBasePDF = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      readFile(e.target.files[0], 'dataURL').then(async (basePdf) => {
        if (designer.current) {
          designer.current.updateTemplate(
            Object.assign(cloneDeep(designer.current.getTemplate()), {
              basePdf,
            })
          );
        }
      });
    }
  };

  const onDownloadTemplate = () => {
    if (designer.current) {
      downloadJsonFile(designer.current.getTemplate(), 'template-design');
    }
  };

  const onPreviewPDF = () => {
    generatePDF(designer.current);
  };

  const onClearTemplate = () => {
    if (designerRef.current) {
      designer.current = new Designer({
        domContainer: designerRef.current,
        template,
      });
    }
  };

  const onSubmit = async (values: FormValues, resetForm: () => void) => {
    if (designer.current) {
      const currentTemplate = designer.current.getTemplate();
      const schemas = Object.fromEntries(
        Object.keys(currentTemplate.schemas[0]).map((key) => {
          const currentType = currentTemplate.schemas[0][key].type;
          return [
            key,
            {
              ...currentTemplate.schemas[0][key],
              type: TEMPLATE_FIELD_TYPES[currentType as LabelTypes],
            },
          ];
        })
      );
      const renderParams = Object.fromEntries(
        Object.keys(currentTemplate.schemas[0]).map((key) => {
          const currentType = currentTemplate.schemas[0][key].type;
          const label =
            currentTemplate.sampledata &&
            Object.prototype.hasOwnProperty.call(
              currentTemplate.sampledata[0],
              key
            )
              ? currentType === LabelTypes.FIXED_TEXT
                ? currentTemplate.sampledata[0][key]
                : key
              : '';
          return [
            key,
            {
              fieldLabel: label,
              fieldType: currentType,
            },
          ];
        })
      );

      const templateId = uuidv4();

      const templateData = {
        ...values,
        templateUid: templateId,
        schemas: [schemas],
        basePdf:
          (designer.current.getTemplate().basePdf as string) ?? BLANK_PDF,
        templateType: 'STATIC',
      };

      const requestObject: CreateCredentialTemplateRequest = {
        name: values.templateName,
        image: values.image,
        description: '',
        designTemplateUid: templateId,
        type: credentialType,
      };

      if (Object.keys(renderParams).length > 0) {
        requestObject.renderParams = renderParams;
      }

      createTemplate(templateData)
        .then(() => {
          if (isCompanyTemplate && currentCompany) {
            createTemplateForCompany({
              ...requestObject,
              companyUid: currentCompany.uid,
            })
              .then(() => {
                navigate(backPath);
              })
              .catch((error) => console.log(error));
          } else {
            createCredentialTemplate(requestObject)
              .then(() => {
                resetForm();
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (
      createTemplateResult.isSuccess &&
      (createCredentialTemplateResult.isSuccess ||
        createTemplateForCompanyResult.isSuccess)
    ) {
      toast.success('Created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [
    createCredentialTemplateResult.isSuccess,
    createTemplateForCompanyResult.isSuccess,
    createTemplateResult.isSuccess,
  ]);

  useEffect(() => {
    if (createTemplateResult.isError) {
      toast.error(
        `Error: ${(createTemplateResult.error as ErrorResponse).data.message}`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
  }, [createTemplateResult.error, createTemplateResult.isError]);

  useEffect(() => {
    if (createCredentialTemplateResult.isError) {
      toast.error(
        `Error: ${
          (createCredentialTemplateResult.error as ErrorResponse).data.message
        }`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
  }, [
    createCredentialTemplateResult.error,
    createCredentialTemplateResult.isError,
  ]);

  return (
    <Page>
      <div className="create-template">
        <div className="create-template__header">
          <Breadcrumbs>
            <Link
              className="create-template-header__bread-crumbs"
              to={backPath}
            >
              {settingsContext?.prevPage ?? 'Templates'}
            </Link>
            <h1>Create New Template</h1>
          </Breadcrumbs>
          <div className="create-template-header__buttons-block">
            <label className="template-designer__label-button">
              Upload Existing Design
              <input
                type="file"
                accept="application/json"
                onChange={(e) => handleLoadTemplate(e, designer.current)}
              />
            </label>
            <Button
              variant="outlined"
              color="primary"
              onClick={onDownloadTemplate}
            >
              Download Current Design
            </Button>
          </div>
        </div>
        <div className="create-template__content">
          <Card className="create-template__card">
            <h2 className="create-template__form-title">Template Editor</h2>
            <Formik
              initialValues={initialTemplateValues}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
            >
              <Form className="template-designer__header">
                <div className="template-designer__fields-block">
                  <PicturePicker
                    name="image"
                    className="create-template-form__picture"
                    size={FILE_SIZE}
                  />
                  <FormControl>
                    <FormLabel>Credential Type</FormLabel>
                    <RadioGroup
                      defaultValue={TEMPLATE_TYPES[0].toUpperCase()}
                      name="credentialType"
                      row
                      onChange={onTypeChange}
                    >
                      {TEMPLATE_TYPES?.map((type, key) => (
                        <FormControlLabel
                          key={key}
                          value={type.toUpperCase()}
                          control={<Radio />}
                          label={type}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <Input name="templateName" label="Template name" required />
                  <div>
                    <label className="input__label" htmlFor="basePdf">
                      Base PDF
                    </label>
                    <ReactSelect
                      name="basePdf"
                      options={BASE_PDF_VALUES}
                      value={basePdf}
                      onChange={onChangeBasePDF}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: 'var(--ncap-300)',
                        },
                      })}
                    />
                  </div>
                  {basePdf?.value === 'other' && (
                    <label className="template-designer__label-button">
                      Upload BasePDF
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={onUploadBasePDF}
                      />
                    </label>
                  )}
                  {isCompanyTemplate ? (
                    <Select
                      name="support"
                      label="Support"
                      options={getSupportValues()}
                      required
                    />
                  ) : null}
                </div>
                <div className="template-designer__buttons-block">
                  <div className="template-designer__buttons-block_first">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={onPreviewPDF}
                      startIcon={<Icon icon={IconType.Document} />}
                    >
                      Preview PDF
                    </Button>
                    {createCredentialTemplateResult.isLoading ||
                    createTemplateForCompanyResult.isLoading ||
                    createTemplateResult.isLoading ? (
                      <Spinner />
                    ) : (
                      <Button
                        variant="contained"
                        startIcon={<Icon icon={IconType.Plus} />}
                        type="submit"
                      >
                        Create
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={
                        <Icon
                          icon={IconType.Cross}
                          className="template-designer__button-icon_discard"
                        />
                      }
                      onClick={onClearTemplate}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </Form>
            </Formik>
          </Card>
          <div className="create-template-designer" ref={designerRef}></div>
        </div>
      </div>
    </Page>
  );
};

export default CreateTemplate;
