import { useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';
import Modal from 'src/shared/components/Modal/Modal';

import { changeModal } from 'src/features/modal/slices/modalSlice';
import { useLazyUnsubscribeFromNotificationsQuery } from 'src/features/notifications/api/notificationsApi';
import { store } from 'src/features/store/store';

import { ReactComponent as LogoutIcon } from './images/logout-icon.svg';

const Logout = () => {
  const navigate = useNavigate();
  const [unsubscribe] = useLazyUnsubscribeFromNotificationsQuery();

  const closeModal = () => {
    store.dispatch(changeModal(null));
  };

  const cleanAndRedirect = () => {
    localStorage.removeItem('ncap-token');
    navigate(paths.signIn);
    window.location.reload();
  };

  const logout = async () => {
    const firebaseToken = localStorage.getItem('firebase-device-token');
    if (firebaseToken) {
      Promise.all([
        unsubscribe({ deviceToken: firebaseToken, topic: 'pya' }),
        unsubscribe({ deviceToken: firebaseToken, topic: 'guest' }),
      ])
        .then(() => {
          cleanAndRedirect();
        })
        .catch((error) => console.log(error));
    } else {
      cleanAndRedirect();
    }
  };

  return (
    <Modal
      open={true}
      title="Log out of NCap™?"
      text="Please confirm you want to log out."
      confirmButtonText="Log out"
      onBackClick={closeModal}
      onConfirmClick={logout}
      color="error"
      icon={<LogoutIcon />}
    />
  );
};

export default Logout;
