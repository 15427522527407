import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import { Button } from '@mui/material';
import { Template } from '@pdfme/common';
import { image, text } from '@pdfme/schemas';
import { Designer } from '@pdfme/ui';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';
import Input from 'src/shared/components/Input/Input';
import DEFAULT_TEMPLATE from 'src/shared/components/TemplateDesigner/default-template.json';
import boolean from 'src/shared/components/TemplateDesigner/plugins/boolean';
import date from 'src/shared/components/TemplateDesigner/plugins/date';
import fixedText from 'src/shared/components/TemplateDesigner/plugins/fixedText';
import number from 'src/shared/components/TemplateDesigner/plugins/number';
import shortText from 'src/shared/components/TemplateDesigner/plugins/shortText';
import {
  cloneDeep,
  downloadJsonFile,
  generatePDF,
  handleLoadTemplate,
  readFile,
} from 'src/shared/components/TemplateDesigner/utils';

import { useCreateDocumentTemplateMutation } from 'src/features/documents/api/documentsApi';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import './TemplateDesigner.scss';

interface FormValues {
  templateUid: string;
  templateName: string;
}

const initialValues = {
  templateUid: '',
  templateName: '',
};

const createTemplateValidation = Yup.object().shape({
  templateUid: Yup.string().required('This field is required'),
  templateName: Yup.string().required('This field is required'),
});

const TemplateDesigner = () => {
  const designerRef = useRef<HTMLDivElement | null>(null);
  const designer = useRef<Designer | null>(null);
  const template: Template = {
    basePdf: DEFAULT_TEMPLATE.basePdf,
    schemas: DEFAULT_TEMPLATE.schemas,
    sampledata: DEFAULT_TEMPLATE.sampledata,
  };
  const [createTemplate, createTemplateResult] =
    useCreateDocumentTemplateMutation();

  useEffect(() => {
    if (designerRef.current) {
      designer.current = new Designer({
        domContainer: designerRef.current,
        template,
        plugins: {
          'Short text': shortText,
          'Long text': text,
          'Fixed text': fixedText,
          Date: date,
          Numeric: number,
          'True/False': boolean,
          Image: image,
        },
      });
    }
  });

  const onChangeBasePDF = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      readFile(e.target.files[0], 'dataURL').then(async (basePdf) => {
        if (designer.current) {
          designer.current.updateTemplate(
            Object.assign(cloneDeep(designer.current.getTemplate()), {
              basePdf,
            })
          );
        }
      });
    }
  };

  const onDownloadTemplate = () => {
    if (designer.current) {
      downloadJsonFile(designer.current.getTemplate(), 'template-design');
    }
  };

  const onPreviewPDF = () => {
    generatePDF(designer.current);
  };

  const onClearTemplate = () => {
    if (designerRef.current) {
      designer.current = new Designer({
        domContainer: designerRef.current,
        template,
      });
    }
  };

  const onSubmit = (values: FormValues, resetForm: () => void) => {
    if (designer.current) {
      const templateData = {
        ...values,
        ...designer.current.getTemplate(),
        basePdf: DEFAULT_TEMPLATE.basePdf,
        templateType: 'STATIC',
      };
      createTemplate(templateData).then(() => {
        if (createTemplateResult.isSuccess) {
          resetForm();
        }
      });
    }
  };

  useEffect(() => {
    if (createTemplateResult.isSuccess) {
      toast.success('Created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [createTemplateResult.isSuccess]);

  return (
    <div className="settings__template-designer">
      <Formik
        initialValues={initialValues}
        validationSchema={createTemplateValidation}
        onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
      >
        <Form className="settings-template-designer__header">
          <div className="settings-template-designer__buttons-block">
            <div className="settings-template-designer__buttons-block_first">
              <Button
                variant="contained"
                startIcon={<Icon icon={IconType.Plus} />}
                type="submit"
              >
                Create
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<Icon icon={IconType.Cross} />}
                onClick={onClearTemplate}
              >
                Clear
              </Button>
            </div>
            <div className="settings-template-designer__buttons-block_second">
              <label className="template-designer__label-button">
                Change BasePDF
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={onChangeBasePDF}
                />
              </label>
              <label className="template-designer__label-button">
                Upload existing design
                <input
                  type="file"
                  accept="application/json"
                  onChange={(e) => handleLoadTemplate(e, designer.current)}
                />
              </label>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onDownloadTemplate}
              >
                Download current design
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onPreviewPDF}
              >
                Preview PDF
              </Button>
            </div>
          </div>
          <div className="settings-template-designer__fields-block">
            <Input name="templateUid" label="Template identifier" />
            <Input name="templateName" label="Template name" />
          </div>
        </Form>
      </Formik>
      <div ref={designerRef}></div>
    </div>
  );
};

export default TemplateDesigner;
