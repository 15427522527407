import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Breadcrumbs, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { paths } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import Card from 'src/shared/components/Card/Card';
import Input from 'src/shared/components/Input/Input';
import Select from 'src/shared/components/Select/Select';
import { ErrorResponse } from 'src/shared/models';

import { useCreateCampaignMutation } from 'src/features/campaigns/api/campaignsApi';
import { CreateCampaignRequest } from 'src/features/campaigns/models';
import {
  clearCampaignDetails,
  setCampaignDetails,
} from 'src/features/campaigns/slices/campaignsSlice';
import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import { MODALS } from 'src/features/modal/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { store } from 'src/features/store/store';
import { useGetTemplatesQuery } from 'src/features/templates/api/templatesApi';

import './CampaignCreating.scss';

const MAX_TITLE_LENGTH = 60;
const MAX_MESSAGE_LENGTH = 178;
const INITIAL_VALUES = {
  title: '',
  message: '',
  targetCriteria: {
    credentialTemplates: [],
  },
};

const createCampaignValidation = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  message: Yup.string().required('This field is required'),
});

const CampaignCreating = (): JSX.Element => {
  const navigate = useNavigate();

  const { data: templates, isError: templatesError } = useGetTemplatesQuery();
  useCheckError(templatesError, 'Error loading Templates');
  const [createCampaign, createResult] = useCreateCampaignMutation();
  const [draft, setDraft] = useState<boolean>(true);

  useCheckAuthorization();

  const getTemplates = () => {
    return templates?.data.map((template) => ({
      label: template.name,
      value: template.uid,
    }));
  };

  useEffect(() => {
    if (draft) {
      if (createResult.isSuccess) {
        store.dispatch(clearCampaignDetails());
        navigate(paths.campaigns);
        return;
      }
      if (createResult.isError) {
        store.dispatch(clearCampaignDetails());
        toast.error(
          `Error: ${(createResult.error as ErrorResponse).data.message}`,
          {
            containerId: CONTAINER_ID_ACTION,
          }
        );
      }
    } else {
      if (createResult.isSuccess) {
        store.dispatch(changeModal(MODALS.CAMPAIGN_PREVIEW));
        return;
      }
      if (createResult.isError) {
        toast.error(
          `Error: ${(createResult.error as ErrorResponse).data.message}`,
          {
            containerId: CONTAINER_ID_ACTION,
          }
        );
      }
    }
  }, [
    createResult.data,
    createResult.error,
    createResult.isError,
    createResult.isSuccess,
    draft,
    navigate,
  ]);

  const onCreateClick = useCallback(
    async (values: CreateCampaignRequest) => {
      const campaignUid = uuidv4();
      const campaignValues = {
        ...values,
        uid: campaignUid,
      };
      await createCampaign(campaignValues);
      store.dispatch(setCampaignDetails(campaignValues));
    },
    [createCampaign]
  );

  return (
    <Page>
      <div className="campaigns">
        <Breadcrumbs>
          <Link className="create-template__bread-crumbs" to={paths.campaigns}>
            NCap™ Engage
          </Link>
          <h1>New campaign</h1>
        </Breadcrumbs>
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => onCreateClick(values)}
          validationSchema={createCampaignValidation}
          enableReinitialize
        >
          {/* {({ values }) => ( */}
          <Form className="create-campaign">
            <Card className="create-campaign__card">
              <Input
                name="title"
                label="Campaign title"
                maxLength={MAX_TITLE_LENGTH}
                placeholder="Write your title"
                required
              />
              <Input
                name="message"
                label="Campaign message"
                maxLength={MAX_MESSAGE_LENGTH}
                placeholder="Write your message"
                required
              />
              <h3 className="create-campaign__card-title">Target Audience</h3>
              <div className="create-campaign__target-audience">
                <Select
                  name="targetCriteria.credentialTemplates"
                  label="Target Recipients"
                  options={getTemplates() ?? []}
                  placeholder="Select the recipients"
                  multiple
                />
                {/* {values.targetCriteria.credentialTemplates.length === 0 ? (
                    <p className="create-campaign__target-audience-info">
                      Leaving this field empty will send a notification to all
                      your seafarers
                    </p>
                  ) : null} */}
              </div>
              <div className="create-campaign__buttons">
                <Button
                  color="secondary"
                  className="settings__form-button_secondary"
                  onClick={() => navigate(paths.campaigns)}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  onClick={() => {
                    setDraft(true);
                  }}
                >
                  Save as Draft
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => setDraft(false)}
                >
                  Create Campaign
                </Button>
              </div>
            </Card>
          </Form>
          {/* )} */}
        </Formik>
      </div>
    </Page>
  );
};

export default CampaignCreating;
