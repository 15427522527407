import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Modal from 'src/shared/components/Modal/Modal';
import { ErrorResponse } from 'src/shared/models';

import { AUTH } from 'src/features/auth/slices/authSlice';
import { setRequestId } from 'src/features/cocRequests/slices/cocRequestsSlice';
import { useSendResetPasswordLinkMutation } from 'src/features/companies/api/companiesApi';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State, store } from 'src/features/store/store';

import { ReactComponent as LockIcon } from './images/lock-icon.svg';

const ResetPassword = () => {
  const navigate = useNavigate();
  const user = useSelector((s: State) => s[AUTH].userProfile);
  const [sendResetPasswordLink, result] = useSendResetPasswordLinkMutation();

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setRequestId(null));
  };

  useEffect(() => {
    if (result.isError) {
      toast.error(`Error: ${(result.error as ErrorResponse).data.message}`, {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, result.error, result.isError]);

  useEffect(() => {
    if (result.isSuccess) {
      closeModal();
      toast.success('Password reset link sent successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, result.isSuccess]);

  return (
    <Modal
      open
      title={`Reset password for "${user?.email}"?`}
      text="A password reset email will be sent to this address."
      confirmButtonText="Reset Password"
      onBackClick={() => store.dispatch(changeModal(null))}
      onConfirmClick={() =>
        user?.userUid && sendResetPasswordLink(user.userUid)
      }
      icon={<LockIcon />}
    />
  );
};

export default ResetPassword;
