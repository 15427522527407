import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Button, Divider } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Input from 'src/shared/components/Input/Input';
import PicturePicker from 'src/shared/components/PicturePicker/PicturePicker';
import Spinner from 'src/shared/components/Spinner/Spinner';
import { ErrorResponse } from 'src/shared/models';
import { PASSWORD_REGEX } from 'src/shared/utils';

import {
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useUpdateProfilePictureMutation,
} from 'src/features/auth/api/authApi';
import { ChangePasswordRequest, ProfileInfo } from 'src/features/auth/models';
import { AUTH, setUserProfile } from 'src/features/auth/slices/authSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State, store } from 'src/features/store/store';

import './GeneralSettings.scss';

const FILE_SIZE = 1048576; // 1MB

type PasswordChangeValues = Omit<ChangePasswordRequest, 'userUid'>;

const initialValues = {
  firstName: '',
  imageUrl: '',
  currentPassword: '',
  newPassword: '',
};

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('This field is required'),
  newPassword: Yup.string()
    .required('This field is required')
    .matches(PASSWORD_REGEX, 'Must contain 8 characters and one digit'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('This field is required'),
});

const GeneralSettings = () => {
  const profile = useSelector((s: State) => s[AUTH].userProfile);
  const profilePicture = `${process.env.REACT_APP_PROFILE_PICTURE_URL}/${profile?.userUid}`;

  const [updateProfile, result] = useUpdateProfileMutation();
  const [updatePicture, updateResult] = useUpdateProfilePictureMutation();
  const [changePassword, passwordChangeResult] = useChangePasswordMutation();

  const accountValues = useMemo(() => {
    return profile
      ? { ...profile, imageUrl: profilePicture ?? '' }
      : initialValues;
  }, [profile, profilePicture]);

  useEffect(() => {
    if (result.isSuccess || updateResult.isSuccess) {
      toast.success('Updated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [result.isSuccess, updateResult.isSuccess]);

  const onSubmit = (values: object) => {
    updateProfile({ ...profile, ...values } as ProfileInfo);

    if (profile?.userUid && (values as ProfileInfo).firstName) {
      store.dispatch(
        setUserProfile({
          userUid: profile.userUid,
          firstName: (values as ProfileInfo).firstName,
        })
      );
    }
  };

  const onImageUpload = (value: File) => {
    const formData = new FormData();
    formData.append('file', value);
    updatePicture(formData);
  };

  const onPasswordChange = (
    values: PasswordChangeValues,
    resetForm: () => void
  ) => {
    profile &&
      changePassword({
        userUid: profile.userUid,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      }).then(() => {
        if (passwordChangeResult.isSuccess) {
          resetForm();
        }
      });
  };

  useEffect(() => {
    if (passwordChangeResult.isError) {
      toast.error(
        `Error: ${(passwordChangeResult.error as ErrorResponse).data.message}`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
    if (passwordChangeResult.isSuccess) {
      toast.success('Password updated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [
    passwordChangeResult.error,
    passwordChangeResult.isError,
    passwordChangeResult.isSuccess,
    result.error,
  ]);

  return (
    <div className="settings__general">
      <Formik initialValues={accountValues} onSubmit={onSubmit}>
        <Form className="settings__name-and-picture">
          <div>
            <h2 className="settings__category-header">Profile name</h2>
            <div className="settings__form_name">
              {`${profile?.firstName ?? '-'} ${profile?.lastName ?? '-'}`}
            </div>
          </div>
          <Divider />
          <PicturePicker
            title="Profile picture"
            name="imageUrl"
            onUploadFile={(value) => onImageUpload(value)}
            size={FILE_SIZE}
          />
        </Form>
      </Formik>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) =>
          onPasswordChange(values, resetForm)
        }
        validationSchema={validationSchema}
      >
        <Form className="settings__password">
          <h2 className="settings__category-header">Change password</h2>
          <div className="settings__form_password">
            <div className="settings__password-form-inputs">
              <Input
                name="currentPassword"
                label="Current password"
                type="password"
              />
              <Input name="newPassword" label="New password" type="password" />
              <Input
                name="passwordConfirm"
                label="Confirm new password"
                type="password"
              />
            </div>
            {passwordChangeResult.isLoading ? (
              <Spinner className="settings__spinner" />
            ) : (
              <Button className="settings__form-button" type="submit">
                Save new password
              </Button>
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default GeneralSettings;
