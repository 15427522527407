import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { paths } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';
import Modal from 'src/shared/components/Modal/Modal';
import { ErrorResponse } from 'src/shared/models';

import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State, store } from 'src/features/store/store';
import { useUpdateTestimonialMutation } from 'src/features/testimonials/api/testimonialsApi';
import { TestimonialRequestStatus } from 'src/features/testimonials/models';
import {
  setRequestId,
  TESTIMONIAL,
} from 'src/features/testimonials/slices/testimonialSlice';

import './ReturnToUnvalidated.scss';

const ReturnToUnvalidated = () => {
  const navigate = useNavigate();
  const requestId = useSelector((s: State) => s[TESTIMONIAL].requestId);
  const [updateTestimonial, result] = useUpdateTestimonialMutation();

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setRequestId(null));
  };

  useEffect(() => {
    if (result.isError) {
      closeModal();
      toast.error(`Error: ${(result.error as ErrorResponse).data.error}`, {
        containerId: CONTAINER_ID_ACTION,
      });
    }
    if (result.isSuccess) {
      closeModal();
      navigate(paths.requests);
      toast.success('SST Returned Successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, result]);

  return (
    <Modal
      open={true}
      title="Return to Office Administrator"
      text="Please provide the reason why you decline this SST."
      inputField
      confirmButtonText="Decline the SST"
      onBackClick={() => store.dispatch(changeModal(null))}
      onConfirmClick={(message?: string) =>
        requestId &&
        updateTestimonial({
          uid: requestId,
          status: TestimonialRequestStatus.SENT_PYA,
          rejectionReason: message ?? '',
        })
      }
      color="error"
      icon={<Icon icon={IconType.Cross} className="modal__icon" />}
    />
  );
};

export default ReturnToUnvalidated;
