import { ReactElement, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { paths } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';

import { UserRoles } from 'src/features/auth/models';
import { AUTH } from 'src/features/auth/slices/authSlice';
import { useCheckError } from 'src/features/hooks';
import { useGetIssuersQuery } from 'src/features/issuers/api/issuersApi';
import { setIssuer } from 'src/features/issuers/slices/issuersSlice';
import { State, store } from 'src/features/store/store';
import TemplatesList from 'src/features/templates/components/TemplatesList/TemplatesList';

import { SettingsContext } from '../../SettingsContext';

import './Issuers.scss';

const Issuers = () => {
  const navigate = useNavigate();
  const userRoles = useSelector((s: State) => s[AUTH].roles);
  const settingsContext = useContext(SettingsContext);
  const { data: issuers, isError } = useGetIssuersQuery();
  useCheckError(isError, 'Error loading Issuers');

  const onOpenClick = useCallback(
    (id: string) => {
      const issuer = issuers?.data.find((elem) => elem.uid === id);
      store.dispatch(setIssuer(issuer ?? null));
      settingsContext?.setPrevPage('Issuers');
      navigate(paths.viewIssuer(id));
    },
    [issuers?.data, settingsContext, navigate]
  );

  const onEditClick = useCallback(
    (id: string) => {
      const issuer = issuers?.data.find((elem) => elem.uid === id);
      store.dispatch(setIssuer(issuer ?? null));
      settingsContext?.setPrevPage('Issuers');
      navigate(paths.editIssuer(id));
    },
    [issuers?.data, settingsContext, navigate]
  );

  const actions = useMemo<(id: string, name?: string) => ReactElement[]>(
    () => (id) => [
      <Button
        key={0}
        className="template-preview__button"
        onClick={() => onOpenClick(id)}
      >
        Open
      </Button>,
      <Button
        key={1}
        className="template-preview__button_info"
        onClick={() => onEditClick(id)}
      >
        Change
      </Button>,
    ],
    [onEditClick, onOpenClick]
  );

  return (
    <div className="settings__issuers">
      <h2 className="settings-issuers__header">Issuers</h2>
      <TemplatesList
        data={issuers?.data ?? []}
        actionButtons={actions}
        className="settings-issuers__list"
      />
      {userRoles?.includes(UserRoles.ISSUER) ||
      userRoles?.includes(UserRoles.ISSUER_BASIC) ? (
        <></>
      ) : (
        <Button
          className="settings__add-button"
          color="info"
          startIcon={<Icon icon={IconType.Plus} />}
          component={Link}
          to={paths.createIssuer}
          onClick={() => settingsContext?.setPrevPage('Issuers')}
        >
          Add a new issuer
        </Button>
      )}
    </div>
  );
};

export default Issuers;
