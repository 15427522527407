import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Breadcrumbs, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { paths, routes } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import Card from 'src/shared/components/Card/Card';
import Input from 'src/shared/components/Input/Input';
import PicturePicker from 'src/shared/components/PicturePicker/PicturePicker';
import { FORM_TYPES } from 'src/shared/enums';

import { COMPANIES } from 'src/features/companies/slices/companiesSlice';
import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { State } from 'src/features/store/store';
import { useUpdateTemplateMutation } from 'src/features/templates/api/templatesApi';
import { CreateTemplateRequest } from 'src/features/templates/models';
import { TEMPLATES } from 'src/features/templates/slices/templatesSlice';

import { SettingsContext } from '../Settings/SettingsContext';

import './TemplateForm.scss';

const INITIAL_VALUES = {
  name: '',
  description: '',
  image: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  description: Yup.string().required('This field is required'),
  image: Yup.string().required('This field is required'),
});

const formTypes = {
  [FORM_TYPES.EDIT]: {
    title: 'Edit template',
    button: 'Edit template',
  },
  [FORM_TYPES.VIEW]: {
    title: 'Template details',
    button: 'Close',
  },
};

type TemplateFormProps = {
  type: Exclude<FORM_TYPES, FORM_TYPES.CREATE>;
};

const TemplateForm = ({ type }: TemplateFormProps): JSX.Element => {
  const navigate = useNavigate();
  const settingsContext = useContext(SettingsContext);

  useCheckAuthorization();

  const template = useSelector((s: State) => s[TEMPLATES].template);
  const currentCompany = useSelector((s: State) => s[COMPANIES].company);

  const initialFormValues = useMemo<CreateTemplateRequest>(
    () => template ?? INITIAL_VALUES,
    [template]
  );
  const [updateTemplate, updatingResult] = useUpdateTemplateMutation();

  const onEditTemplate = (values: CreateTemplateRequest) => {
    template && updateTemplate({ ...values, id: template?.uid });
  };

  useCheckError(updatingResult.isError, 'Error updating the template');

  useEffect(() => {
    if (updatingResult.isSuccess) {
      navigate(paths.settingsTemplates);
      toast.success('Updated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, updatingResult.isSuccess]);

  const backPath =
    settingsContext?.prevPage === 'Company'
      ? settingsContext?.getPath(
          currentCompany?.uid,
          routes.companies.templates
        )
      : paths.settingsTemplates;

  return (
    <Page>
      <div className="create-template">
        <Breadcrumbs>
          <Link className="create-template__bread-crumbs" to={backPath}>
            {settingsContext?.prevPage ?? 'Templates'}
          </Link>
          <h1>{formTypes[type].title}</h1>
        </Breadcrumbs>
        <Card className="create-template__card">
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            onSubmit={
              type === FORM_TYPES.EDIT
                ? onEditTemplate
                : () => console.log('submit')
            }
            validationSchema={validationSchema}
          >
            <Form className="create-template-form">
              <PicturePicker
                title="Template picture"
                name="image"
                className="create-template-form__picture"
                disabled={type === FORM_TYPES.VIEW}
              />
              <div className="create-template-form__fields">
                <Input
                  name="name"
                  placeholder="Name your template"
                  label="Name"
                  disabled={type === FORM_TYPES.VIEW}
                />
                <Input
                  name="description"
                  placeholder="What is this template about?"
                  label="Description"
                  disabled={type === FORM_TYPES.VIEW}
                />
              </div>
              <div className="create-template-form__buttons">
                <Button
                  color="secondary"
                  className="settings__form-button_secondary"
                  component={Link}
                  to={backPath}
                >
                  {type === FORM_TYPES.VIEW
                    ? formTypes[type].button
                    : 'Discard'}
                </Button>
                {type !== FORM_TYPES.VIEW && (
                  <Button variant="contained" type="submit">
                    {formTypes[type].button}
                  </Button>
                )}
              </div>
            </Form>
          </Formik>
        </Card>
      </div>
    </Page>
  );
};

export default TemplateForm;
