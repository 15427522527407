import { useEffect } from 'react';

import { useField, useFormikContext } from 'formik';

import Input from 'src/shared/components/Input/Input';
import Select from 'src/shared/components/Select/Select';
import { FormField } from 'src/shared/models';

import { CoCStatus } from 'src/features/responsiblePersons/models';

import { COC_STATUS_OPTIONS, POSITION_OPTIONS } from '../../AddRP';

const RPInfo = () => {
  const { setFieldValue } = useFormikContext<FormField>();

  const [cocNumberField] = useField('cocNo');
  const [issuingAuthorityField] = useField('cocAuthority');
  const [cocStatusField] = useField('cocStatus');

  useEffect(() => {
    cocNumberField.value === '' || issuingAuthorityField.value === ''
      ? setFieldValue('cocStatus', CoCStatus.NOT_REGISTERED)
      : setFieldValue('cocStatus', CoCStatus.ACTIVE);
  }, [
    cocNumberField.value,
    cocStatusField.value,
    issuingAuthorityField.value,
    setFieldValue,
  ]);

  return (
    <div className="responsible-person-form__info">
      <Select
        name="position"
        label="Position"
        options={POSITION_OPTIONS}
        otherOption
      />
      <Input name="yachtName" label="Yacht name" />
      <Input name="cocNo" label="CoC Number" />
      <Input name="cocAuthority" label="CoC Issuing Authority" />
      <Select
        name="cocStatus"
        label="CoC Status"
        options={COC_STATUS_OPTIONS}
        disabled
      />
      <Input name="email" label="Email" />
    </div>
  );
};

export default RPInfo;
