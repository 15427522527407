import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Alert } from '@mui/material';

import { useConfirmSecondaryEmailMobileMutation } from 'src/features/mobile/api/mobileApi';

import './ConfirmSecondaryEmailMobile.scss';

type SeverityType = 'success' | 'info' | 'warning' | 'error';

const ConfirmSecondaryEmailMobile = () => {
  const [severity, setSeverity] = useState<SeverityType>('warning');
  const [message, setMessage] = useState('Invalid link');

  const [searchParams] = useSearchParams();

  const [
    confirmSecondaryEmailMobile,
    { isLoading, isError, isSuccess, isUninitialized },
  ] = useConfirmSecondaryEmailMobileMutation();

  useEffect(() => {
    const code = searchParams.get('code');
    const email = searchParams.get('email');
    if (code && email) {
      confirmSecondaryEmailMobile({
        secondaryEmail: email,
        verificationCode: code,
      });
    }
  }, [confirmSecondaryEmailMobile, searchParams]);

  useEffect(() => {
    if (isUninitialized) {
      setSeverity('warning');
      setMessage('Invalid link');
      return;
    }
    if (isLoading) {
      setSeverity('info');
      setMessage('Loading');
      return;
    }
    if (isError) {
      setSeverity('error');
      setMessage(
        'Something went wrong. Please, try again later or contact the support'
      );
      return;
    }
    if (isSuccess) {
      setSeverity('success');
      setMessage(
        'Success! Please, return to check the new secondary email address'
      );
      return;
    }
  }, [isError, isLoading, isSuccess, isUninitialized]);

  return (
    <main className="confirm-secondary-email-mobile">
      <Alert variant="filled" severity={severity}>
        {message}
      </Alert>
    </main>
  );
};

export default ConfirmSecondaryEmailMobile;
