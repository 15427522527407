import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';
import { DefaultResponse } from 'src/shared/models';

import {
  AddGUESTApplicationComment,
  ChangeGUESTApplicationStatus,
  GetGUESTApplicationByIdRequest,
  GetGUESTApplicationByIdResponse,
  GetGUESTApplicationComments,
  GetGUESTApplicationDocuments,
  GetGUESTApplicationDocumentsRequest,
  GetGUESTApplicationImage,
  GetGUESTApplicationsResponse,
  UploadGUESTApplicationDocument,
} from '../models';

export const GUEST_APPLICATION_API = 'GUEST_APPLICATION_API';

const cocRequestsApi = createApi({
  reducerPath: GUEST_APPLICATION_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    {
      maxRetries: 0,
    }
  ),
  tagTypes: ['GUEST_APPLICATION_API'],
  endpoints: (builder) => ({
    getGUESTApplications: builder.query<GetGUESTApplicationsResponse, string[]>(
      {
        query: (statuses: string[]) => {
          const status = statuses.reduce(
            (accumulator, currentStatus) =>
              accumulator + `status=${currentStatus}&`,
            ''
          );
          return {
            method: 'GET',
            // url: `guest-application?status=${statuses}`,
            url: `guest-application?${status}`,
          };
        },
        providesTags: ['GUEST_APPLICATION_API'],
      }
    ),
    getGUESTApplicationById: builder.query<
      GetGUESTApplicationByIdResponse,
      GetGUESTApplicationByIdRequest
    >({
      query: (request) => ({
        method: 'GET',
        url: `guest-application/${request.applicationUid}`,
        headers: {
          'form-auth': request.authToken ?? '',
          'form-object': request.applicationUid,
        },
      }),
      providesTags: ['GUEST_APPLICATION_API'],
    }),
    getGUESTApplicationDocuments: builder.query<
      GetGUESTApplicationDocuments,
      GetGUESTApplicationDocumentsRequest
    >({
      query: (request) => ({
        method: 'GET',
        url: `guest-application/${request.applicationUid}/document`,
        headers: {
          'form-auth': request.authToken ?? '',
          'form-object': request.applicationUid,
        },
      }),
      providesTags: ['GUEST_APPLICATION_API'],
    }),
    uploadGUESTApplicationDocument: builder.mutation<
      DefaultResponse,
      UploadGUESTApplicationDocument
    >({
      query: (request) => ({
        method: 'PUT',
        url: `guest-application/${request.applicationUid}/document/${request.uid}`,
        body: request.document,
        formData: true,
        headers: {
          'form-auth': request.authToken,
          'form-object': request.applicationUid,
        },
      }),
    }),
    getGUESTApplicationComments: builder.query<
      GetGUESTApplicationComments,
      string
    >({
      query: (testimonialId) => ({
        method: 'GET',
        url: `guest-application/${testimonialId}/comment`,
      }),
    }),
    addGUESTApplicationComment: builder.mutation<
      void,
      AddGUESTApplicationComment
    >({
      query: (request) => ({
        method: 'POST',
        url: `guest-application/${request.uid}/comment`,
        body: {
          body: request.comment,
        },
      }),
    }),
    getGUESTApplicationImage: builder.query<
      DefaultResponse,
      GetGUESTApplicationImage
    >({
      query: (request) => ({
        method: 'GET',
        url: `guest-application/${request.applicationUid}/image?preview=true`,
        headers: {
          'form-auth': request.authToken,
          'form-object': request.applicationUid,
        },
      }),
    }),
    uploadGUESTApplicationImage: builder.mutation<
      DefaultResponse,
      UploadGUESTApplicationDocument
    >({
      query: (request) => ({
        method: 'PUT',
        url: `guest-application/${request.applicationUid}/image`,
        body: request.document,
        formData: true,
        headers: {
          'form-auth': request.authToken,
          'form-object': request.applicationUid,
        },
      }),
      invalidatesTags: ['GUEST_APPLICATION_API'],
    }),
    changeGUESTApplicationStatus: builder.mutation<
      DefaultResponse,
      ChangeGUESTApplicationStatus
    >({
      query: (request) => ({
        method: 'PUT',
        url: `guest-application/${request.uid}/status`,
        headers: {
          'form-auth': request.authToken ?? '',
          'form-object': request.uid,
        },
        body: {
          status: request.status,
        },
      }),
      invalidatesTags: ['GUEST_APPLICATION_API'],
    }),
  }),
});

export const {
  useGetGUESTApplicationsQuery,
  useGetGUESTApplicationByIdQuery,
  useLazyGetGUESTApplicationByIdQuery,
  useGetGUESTApplicationDocumentsQuery,
  useUploadGUESTApplicationDocumentMutation,
  useGetGUESTApplicationCommentsQuery,
  useAddGUESTApplicationCommentMutation,
  useUploadGUESTApplicationImageMutation,
  useGetGUESTApplicationImageQuery,
  useChangeGUESTApplicationStatusMutation,
} = cocRequestsApi;

export const cocRequestsMiddleware = cocRequestsApi.middleware;

export default cocRequestsApi.reducer;
