import { ReactElement, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { paths } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';

import { UserRoles } from 'src/features/auth/models';
import { AUTH } from 'src/features/auth/slices/authSlice';
import { useCheckError } from 'src/features/hooks';
import { State, store } from 'src/features/store/store';
import { useGetTemplatesQuery } from 'src/features/templates/api/templatesApi';
import TemplatesList from 'src/features/templates/components/TemplatesList/TemplatesList';
import { setTemplate } from 'src/features/templates/slices/templatesSlice';

import { SettingsContext } from '../../SettingsContext';

import './Templates.scss';

const Templates = () => {
  const navigate = useNavigate();
  const settingsContext = useContext(SettingsContext);
  const { data: templates, isError } = useGetTemplatesQuery();
  useCheckError(isError, 'Error loading Templates');
  const userRoles = useSelector((s: State) => s[AUTH].roles);

  const onOpenClick = useCallback(
    (id: string) => {
      const template = templates?.data.find((elem) => elem.uid === id);
      store.dispatch(setTemplate(template ?? null));
      settingsContext?.setPrevPage('Templates');
      navigate(paths.viewTemplate(id));
    },
    [navigate, settingsContext, templates?.data]
  );

  const onEditClick = useCallback(
    (id: string) => {
      const template = templates?.data.find((elem) => elem.uid === id);
      store.dispatch(setTemplate(template ?? null));
      settingsContext?.setPrevPage('Templates');
      navigate(paths.editTemplate(id));
    },
    [navigate, settingsContext, templates?.data]
  );

  const actions = useMemo<(id: string, name?: string) => ReactElement[]>(
    () => (id) => [
      <>
        <Button
          key={0}
          className="template-preview__button"
          onClick={() => onOpenClick(id)}
        >
          Open
        </Button>
        {userRoles?.includes(UserRoles.ISSUER_ADMIN) ? (
          <Button
            key={1}
            className="template-preview__button_info"
            onClick={() => onEditClick(id)}
          >
            Change
          </Button>
        ) : null}
      </>,
    ],
    [onEditClick, onOpenClick, userRoles]
  );

  return (
    <div className="settings__templates">
      <h2 className="settings-templates__header">Templates</h2>
      <TemplatesList
        data={templates?.data ?? []}
        actionButtons={actions}
        className="settings-templates__list"
      />
      {userRoles?.includes(UserRoles.ISSUER_ADMIN) ||
      userRoles?.includes(UserRoles.NAVOZYME_ADMIN) ? (
        <Button
          className="settings__add-button"
          color="info"
          startIcon={<Icon icon={IconType.Plus} />}
          component={Link}
          to={paths.createTemplate}
          onClick={() => settingsContext?.setPrevPage('Templates')}
        >
          Create a new template
        </Button>
      ) : null}
    </div>
  );
};

export default Templates;
