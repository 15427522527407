import { useEffect, useState } from 'react';
import { FileUploader as Uploader } from 'react-drag-drop-files';

import { Button } from '@mui/material';
import { ErrorMessage, useFormikContext } from 'formik';

import FilePreview from 'src/shared/components/FilePreview/FilePreview';
import { FormField } from 'src/shared/models';

import { ApplicationDocument } from 'src/features/cocRequests/models';

import TextError from '../TextError/TextError';

import './FileUploader.scss';

const FILE_TYPES = ['PDF'];
const MAX_SIZE = 5;

interface FileUploaderProps {
  fileTypes?: string[];
  maxSize?: number;
  fieldName?: string;
  fieldContent?: JSX.Element;
  hideUploadArea?: boolean;
  onChange?: (param: boolean) => void;
}

const FileUploader = ({
  fileTypes = FILE_TYPES,
  maxSize = MAX_SIZE,
  fieldName,
  fieldContent,
  hideUploadArea = true,
  onChange,
}: FileUploaderProps) => {
  const [file, setFile] = useState<File[]>([]);
  const [error, setError] = useState<string>();
  const { setFieldValue, values } = useFormikContext<FormField>();
  const [initialFile, setInitialFile] = useState<ApplicationDocument>();

  const handleChange = (file: File[]) => {
    setError(undefined);
    setFile(file);
    if (fieldName && values[fieldName]) {
      setInitialFile(values[fieldName] as unknown as ApplicationDocument);
    }
    fieldName && setFieldValue(fieldName, file[0]);
    onChange?.(true);
  };

  const onCrossClick = () => {
    if (fieldName) {
      if (initialFile) {
        setFieldValue(fieldName, initialFile);
        onChange?.(false);
      } else {
        setFile([]);
        setFieldValue(fieldName, null);
        onChange?.(false);
      }
    }
  };

  useEffect(() => {
    if (fieldName && values[fieldName]) {
      setFile([values[fieldName] as unknown as File]);
    }
  }, [fieldName, values]);

  const content = (
    <div className="file-uploader__content">
      <p>{`${fileTypes} format, up to ${maxSize}MB`}</p>
      <p>Drag a document here to upload</p>
      <p>or</p>
      <Button className="file-uploader__button">Upload from your device</Button>
    </div>
  );

  return (
    <>
      {!file.length || !hideUploadArea ? (
        <Uploader
          multiple={true}
          handleChange={handleChange}
          name={fieldName ?? 'file'}
          types={fileTypes}
          classes="file-uploader"
          label="Drag a document here to upload or Upload from your device"
          maxSize={maxSize}
          onSizeError={() => {
            setError('File size exceeds maximum limit');
          }}
          onTypeError={() => {
            setError('File type is not supported');
          }}
        >
          {fieldContent ?? content}
        </Uploader>
      ) : null}
      {error ? <p className="file-uploader__error">{error}</p> : null}
      {file.length ? (
        <FilePreview
          name={file[0].name ?? fieldName}
          onCrossClick={onCrossClick}
          hideCrossButton={
            !!(file[0] as unknown as ApplicationDocument).uid ||
            (file[0] as unknown as string) === 'uploaded'
          }
        />
      ) : null}
      <ErrorMessage name={fieldName ?? 'file'} component={TextError} />
    </>
  );
};

export default FileUploader;
