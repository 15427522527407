import { useEffect } from 'react';

import { useFormikContext } from 'formik';

import Select from 'src/shared/components/Select/Select';
import { FormField } from 'src/shared/models';

import { store } from 'src/features/store/store';
import {
  LabelTypes,
  RECIPIENT_INITIAL_VALUES,
  Template,
} from 'src/features/templates/models';
import {
  setRenderParamsNames,
  setTemplate,
} from 'src/features/templates/slices/templatesSlice';

import './ChooseTemplate.scss';

type ChooseTemplateProps = {
  templates: Template[];
  onSelect?: () => void;
};

const ChooseTemplate = ({ templates }: ChooseTemplateProps) => {
  const { values } = useFormikContext<FormField>();

  useEffect(() => {
    const id = values.templateUid;

    let template: Template | undefined = undefined;
    if (templates.length === 1) {
      template = templates[0];
    } else {
      template = templates.find((template) => template.uid === id);
    }

    store.dispatch(setTemplate(template ?? null));
    const names =
      template && template.designTemplateUid !== ''
        ? Object.keys(template.renderParams).filter(
            (name) =>
              !Object.keys(RECIPIENT_INITIAL_VALUES).includes(name) &&
              template?.renderParams[name].fieldType !== LabelTypes.FIXED_TEXT
          )
        : [];
    store.dispatch(setRenderParamsNames(names));
  }, [values.templateUid, templates]);

  const getTemplatesOptions = () => {
    return templates.map((template) => {
      return {
        value: template.uid,
        label: `${template.designTemplateUid ? '*' : ''}${template.name}`,
      };
    });
  };

  return (
    <>
      {templates.length > 1 && (
        <div className="choose-template__select">
          <Select
            name="templateUid"
            label="Template"
            options={getTemplatesOptions()}
            placeholder="Select Template"
            required
          />
          <p className="choose-template__select-label">
            ‘*’ = design available
          </p>
        </div>
      )}
    </>
  );
};

export default ChooseTemplate;
