import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import Modal from 'src/shared/components/Modal/Modal';

import { useGetGUESTApplicationCommentsQuery } from 'src/features/cocRequests/api/cocRequestsApi';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { store } from 'src/features/store/store';
import { setRequestId } from 'src/features/testimonials/slices/testimonialSlice';

import './CommunicationTrail.scss';

const DATE_FORMAT = 'DD MMM YYYY';
const TIME_FORMAT = 'HH:mm';

const CommunicationTrail = () => {
  const { id } = useParams();
  const { data: comments } = useGetGUESTApplicationCommentsQuery(id as string, {
    skip: !id,
  });

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setRequestId(null));
  };

  const commentsList = (
    <ul className="communication-trail-modal__comments-list">
      {comments?.data.map((comment, index) => {
        const date = dayjs(comment.createdAt);
        return (
          <li key={index} className="communication-trail-modal-comments__item">
            <p className="communication-trail-modal-comments__item-body">
              {comment.body}
            </p>
            <p className="communication-trail-modal-comments__item-created-at">
              {`Sent on ${date.format(DATE_FORMAT)} at ${date.format(
                TIME_FORMAT
              )}`}
            </p>
          </li>
        );
      })}
    </ul>
  );

  return (
    <Modal
      open={true}
      title="Communication Trail"
      text="The following communication entries have been made by GUEST during the application approval process:"
      content={commentsList}
      hideConfirmButton
      onBackClick={closeModal}
    />
  );
};

export default CommunicationTrail;
