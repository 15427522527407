import { IconButton } from '@mui/material';

import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';

import './FilePreview.scss';

export type FilePreviewProps = {
  name: string;
  onCrossClick: () => void;
  hideCrossButton?: boolean;
  className?: string;
};

const FilePreview = ({
  name,
  onCrossClick,
  hideCrossButton = false,
  className,
}: FilePreviewProps) => {
  return (
    <div className="file-preview">
      <div className={`file-preview__info ${className ?? ''}`}>
        <Icon
          icon={IconType.Document}
          className="template-preview-info__icon"
        />
        <h3 className="template-preview-info__name">{name}</h3>
      </div>
      {hideCrossButton ? (
        <></>
      ) : (
        <div className="template-preview__actions">
          <IconButton onClick={onCrossClick}>
            <Icon icon={IconType.Cross} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default FilePreview;
